import React, { useEffect } from 'react';
import "./style.css"
import NewNavbar from '../NewNavbar/NewNavbar';
import FooterDesktop from '../Footer/FooterDesktop';

const MentionsLegales = () => {
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
        <NewNavbar/>
    <div className="mentions-legales">
      <h1>Mentions Légales</h1>

      <h2>1. Informations sur l'éditeur du site</h2>
      <p>
        <strong>Nom de l'entreprise :</strong> Techtide Solutions<br />
        <strong>représentant légal :</strong> Elmay Nizar<br />
        <strong>Statut juridique :</strong> SARL<br />
        <strong>Capital social :</strong> 1000 TND<br />
        <strong>Adresse :</strong> 02 Ibn Khaldoun C Nasr, 2094, Mnihla<br />
        <strong>Téléphone :</strong> +216 51 01 39 89<br />
        <strong>Email :</strong> techtide.solutions.tn@gmail.com<br />
        <strong>Numéro d'identification fiscale :</strong> 1870054/N<br />
        <strong>Immatriculation :</strong> RNE de Tunis<br />
      </p>

      <h2>2. Hébergeur du site</h2>
      <p>
        <strong>Nom de l'hébergeur :</strong> hostinger<br />
        <strong>Adresse :</strong> https://www.hostinger.com<br />
        <strong>Téléphone :</strong> +37064503378<br />
        <strong>Email :</strong> domains@hostinger.com<br />
      </p>

      <h2>3. Propriété intellectuelle</h2>
      <p>
        Le contenu de ce site (textes, images, vidéos, logos, etc.) est la propriété exclusive de Techtide Solutions ou de ses partenaires, et est protégé par les lois en vigueur sur la propriété intellectuelle. Toute reproduction, distribution ou modification du contenu, sans l'autorisation écrite de Techtide Solutions, est strictement interdite et constituerait une contrefaçon.
      </p>

      <h2>4. Limitation de responsabilité</h2>
      <p>
        Techtide Solutions s’efforce d’assurer l’exactitude des informations diffusées sur son site. Toutefois, nous ne pouvons être tenus responsables des erreurs, omissions, ou de l'interprétation des informations fournies. L'utilisation du site se fait sous la seule responsabilité de l'utilisateur.
      </p>

      <h2>5. Protection des données personnelles</h2>
      <p>
        Techtide Solutions est soucieuse de la protection des données personnelles de ses utilisateurs. Pour plus d’informations sur le traitement des données, consultez notre <a href="/Politique">Politique de Confidentialité</a>.
      </p>

      <h2>6. Droit applicable et juridiction compétente</h2>
      <p>
        Les présentes mentions légales sont régies par la législation tunisienne. En cas de litige, et à défaut de solutions amiable, les tribunaux de  Tunis seront seuls compétents.
      </p>

      <h2>7. Contact</h2>
      <p>
        Pour toute question relative à l'utilisation du site, merci de nous contacter à l'adresse suivante : techtide.solutions.tn@gmail.com.
      </p>
    </div>
    <FooterDesktop/>
    </div>
  );
};

export default MentionsLegales;
