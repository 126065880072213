import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import da_pietro_logo from "./da_pietro_logo.png"
import ahmed_img from "./ahmed_img.jpg"
import DaPietro_Perso_vcf from "./DaPietro_Perso_vcf.vcf"

const DaPietro_Perso = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+216 25 88 06 27"}
            email={"ahmed.hergal@gmail.com"}
            vcfFilePath={DaPietro_Perso_vcf}
          />

          <BodyCarte
            firstname={"Ahmed"}
            lastname={"Hergal"}
            title={"Ceo"}
            address={"le bardo,tunis,2000"}
            phoneNumber={"+216 25 88 06 27"}
            localPhoneNumber={""}
            email={"ahmed.hergal@gmail.com"}
            website={"www.da-pietro.com"}
            imagePath={ahmed_img}
            Logo={da_pietro_logo}
            facebookHref="https://www.facebook.com/ahmed.hergoal?mibextid=LQQJ4d"
            instagramHref="https://tn.linkedin.com/in/ahmed-hergal-67576386"
            linkedinHref="https://www.instagram.com/ahmed.hergal?igsh=MTFjcHJuYXM1OHNzNw%3D%3D&utm_source=qr"
          />
        </>
    </div>
  );
};

export default DaPietro_Perso;