import React, { useEffect } from 'react'
import "./style.css"
import NewNavbar from '../NewNavbar/NewNavbar'
import FooterDesktop from '../Footer/FooterDesktop'

const Condition_generale = () => {
    useEffect(() => {
        // Scroll to top with smooth behavior when the component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
  return (
    <div className='Condition_generale'>
        <NewNavbar/>
        <div className='Condition_generale1'>
        <h1>Conditions Générales de Vente (CGV)</h1>
        <h2>Les présentes Conditions Générales de Vente (CGV) définissent les modalités selon lesquelles Techtide Solutions,<br/> 
            société experte dans la fourniture de services informatiques et la commercialisation d’équipements électroniques, 
            propose ses produits et services.<br/>
            Ces conditions régissent l'ensemble des relations commerciales entre Techtide Solutions et ses clients.<br/>
            Ces CGV sont conformes aux dispositions du Code de commerce tunisien.
            </h2>
        </div>
        <div className='Condition_generale2'>
            <h1>1. Identification des Parties</h1>
            <p>Techtide Solution<br/>
Adresse : 02 Ibn Khaldoun C Nasr, 2094, Mnihla<br/>
Email : techtide.solutions.tn@gmail.com<br/>
Téléphone : +216 51 01 39 89<br/>
Client : Les informations complètes du client, y compris les coordonnées et les informations professionnelles,
 sont recueillies lors de la commande.</p>
            <h1>2. Objet du Contrat</h1>
            <p>Ces CGV définissent les conditions de vente de nos services informatiques (développement, conseil, maintenance) 
                et de nos équipements électroniques (NFC, solutions technologiques, etc.). 
                Ce contrat englobe toutes les prestations proposées par Techtide Solutions, 
                que ce soit pour des services ou des produits matériels.</p>
            <h1>3. Prix et Modalités de Paiement</h1>
            <p>Les prix sont exprimés en dinars tunisiens (TND), hors taxes (HT), et incluent le détail des services ou produits choisis. 
                Les modalités de paiement acceptées comprennent: virement bancaire, chèque de banque, espèces.</p>
                <ul>
                    <li>
                        <h3>Conditions de paiement :</h3>
                        <p>Les factures sont payables selon 
                            les modalités définies lors de la commande (par ex. : acompte à la commande, solde à la livraison).</p>
                    </li>
                    <li>
                        <h3>Retards de paiement : </h3>
                        <p>En cas de retard, des pénalités peuvent être appliquées selon les dispositions du Code de commerce tunisien.</p>
                    </li>
                </ul>
            <h1>4. Livraison et Délais</h1>
            <p>Les délais de livraison des équipements sont spécifiés lors de la commande. 
                Techtide Solutions s’engage à informer le client de tout retard éventuel.</p>
                <ul>
                    <li>
                        <h3>Responsabilité de livraison : </h3>
                        <p>Techtide Solutions n’est pas responsable des retards dus à des circonstances indépendantes de sa volonté 
                            (grèves, interruptions de transport, etc.).</p>
                    </li>
                    <li>
                        <h3>Modalités de livraison :</h3>
                        <p>Les équipements peuvent être livrés ou mis à disposition pour retrait selon les modalités convenues avec le client.</p>
                    </li>
                </ul>
            <h1>5. Droit de Rétractation</h1>
            <p>Conformément aux dispositions légales, le client dispose d’un droit de rétractation de 
                7 jours à compter de la livraison des produits pour annuler la commande, sauf pour les produits personnalisés ou les services déjà exécutés. 
                En cas de rétractation, le client sera remboursé sous réserve du retour des produits en parfait état.</p>
            <h1>6. Garanties et Responsabilités</h1>
            <p>Techtide Solutions garantit que les produits et services fournis sont conformes aux spécifications annoncées. 
                En cas de défaut ou de non-conformité, le client dispose d’un délai de 48 heures
                pour contacter Techtide Solutions et bénéficier d’un échange, d’une réparation ou d’un remboursement.</p>
            <h1>7. Droit Applicable et Juridiction Compétente</h1>
            <p>Les présentes CGV sont régies par le droit tunisien. En cas de litige, 
                les parties conviennent de se soumettre à la juridiction compétente du tribunal de Tunis.</p>
            <h1>8. Protection des Données Personnelles</h1>
            <p>Techtide Solutions respecte les lois tunisiennes sur la protection des données personnelles. 
                Les données collectées sont utilisées exclusivement dans le cadre de la gestion des commandes et de la relation commerciale avec le client. 
                Pour plus de détails, consultez notre Politique de Confidentialité.</p>
            <h1>9. Force Majeure</h1>
            <p>Techtide Solutions ne saurait être tenue responsable en cas d’impossibilité d’exécuter ses obligations en raison d’événements de force majeure, 
                tels que définis par la loi tunisienne, 
                y compris mais sans s’y limiter : catastrophes naturelles, conflits sociaux, perturbations des moyens de transport.</p>
                <h1>10. Résiliation du Contrat</h1>
            <p>Le client peut résilier le contrat selon un préavis de 3 jours. 
                Toute résiliation avant le terme de la prestation peut entraîner des frais de résiliation, précisés lors de la commande. 
                En cas de résiliation, le client reste redevable des services déjà fournis et facturés.</p>
        </div>
        <h3 className='Condition_generale_h3'>Pour toute information complémentaire, 
            n'hésitez pas à contacter notre équipe par email à [techtide.solutions.tn@gmail.com] ou par téléphone au [+216 51 01 39 89].</h3>
        <FooterDesktop/>
    </div>
  )
}

export default Condition_generale