import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import glamourLogo from './glamourLogo.png';
import glamourLogo2 from './glamourLogo2.jpg';
import AouiniTarek_vcf from "./AouiniTarek_vcf.vcf"

const AouiniTarek = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+21622822075"}
            email={"Glamourmedicalc@gmail.com"}
            vcfFilePath={AouiniTarek_vcf}
          />

          <BodyCarte
            firstname={"TAREK"}
            lastname={"AOUINI"}
            title={"Directeur général et Médecin esthétique"}
            address={"Jardins de carthage Tunis"}
            phoneNumber={"+21622822075"}
            localPhoneNumber={"+32470663970"}
            email={"Glamourmedicalc@gmail.com"}
            website={""}
            imagePath={glamourLogo}
            Logo={glamourLogo2}
            facebookHref="https://www.facebook.com/p/Glamour-Medical-Center-100070465706058/"
            instagramHref="https://www.instagram.com/glamourmedicalcenter/"
          />
        </>
    </div>
  );
};

export default AouiniTarek;