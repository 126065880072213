import React, { useEffect} from 'react';
import "./ContactUs.css"
import ContactForm from './ContactForm/ContactForm'
import ContactTop from './ContactTop/ContactTop'
import { Helmet } from 'react-helmet';
import FooterDesktop from '../Footer/FooterDesktop';
import NewNavbar from '../NewNavbar/NewNavbar';

const ContactUs = () => {
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className='ContactUs'>
    <Helmet>
      <title>TECHTIDE - Contact_Us</title>
      <meta name="description" content="Contactez TECHTIDE pour toutes vos questions, demandes de partenariat ou collaborations. Notre équipe est prête à vous assister et à explorer des opportunités innovantes ensemble. Nous sommes impatients de recevoir de vos nouvelles et de collaborer pour atteindre de nouveaux sommets technologiques."/>
    </Helmet>
    <NewNavbar/>
    <div className='ContactUs_Content'>
    <ContactTop/>
    <ContactForm/>
    </div>
    <FooterDesktop/>
  </div>
  )
}

export default ContactUs