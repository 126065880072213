import React from 'react';
import nexus from './nexus.png';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import Majdi_pdp from './Majdi_pdp.jpg';
import ElloumiMajdi from './ElloumiMajdi.vcf';

const Majdi = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"23880778"}
            email={"majdi.elloumi@nexuscontactcenter.com"}
            vcfFilePath={ElloumiMajdi}
          />

          <BodyCarte
            firstname={"Majdi"}
            lastname={"Elloumi"}
            title={"DPO (Nexus Contact Center)"}
            address={"1-1 Immeuble Express Centre Urbain Nord , Tunis 1080"}
            phoneNumber={"+216 23 88 07 78"}
            localPhoneNumber={"+216 31 32 43 00"}
            email={"majdi.elloumi@nexuscontactcenter.com"}
            website={"https://nexuscontactcenter.com/"}
            linkedinHref="https://www.linkedin.com/in/majdi-elloumi-48787668/"
            imagePath={Majdi_pdp}
            Logo={nexus}
          />
        </>
    </div>
  );
};

export default Majdi;