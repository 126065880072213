import React from 'react';
import nexus from './nexus.png';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import mghaieth_pdp from './mghaieth_pdp.jpeg';
import Ridha_Mghaieth_vcf from './Ridha_Mghaieth_vcf.vcf';

const Mghaieth = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+216 22 48 00 18"}
            email={"mohamed.mghaieth@nexuscontactcenter.com"}
            vcfFilePath={Ridha_Mghaieth_vcf}
          />

          <BodyCarte
            firstname={"Mghaieth"}
            lastname={"Med Ridha"}
            title={"Business Development Manager"}
            address={"1-1 Immeuble Express Centre Urbain Nord , Tunis 1080"}
            phoneNumber={"+216 22 48 00 18"}
            localPhoneNumber={"+33 6 12 83 85 19"}
            email={"mohamed.mghaieth@nexuscontactcenter.com"}
            website={"https://nexuscontactcenter.com/"}
            linkedinHref="https://www.linkedin.com/in/mohamed-mghaieth-77189970/"
            imagePath={mghaieth_pdp}
            Logo={nexus}
          />
        </>
    </div>
  );
};

export default Mghaieth;