// src/components/HeroSection.js
import React, { useEffect, useRef } from 'react';
import './HeroSection.css';
import { Fade } from 'react-reveal';
import { useNavigate } from 'react-router-dom';

function AnimatedBackground({ interval = 100 }) {

    const ref = useRef(null);
    let prevTime = undefined;
    const animationRef = useRef(null); // Track the animation frame ID

    function getRandomSpawnLocation() {
        if (!ref.current) return ["0px", "0px"];
        
        const spawnLocationX = Math.round(
            Math.random() * ref.current.parentElement.scrollWidth - 80
        );
        const spawnLocationY = Math.round(
            Math.random() * ref.current.parentElement.scrollHeight - 80
        );
        return [`${spawnLocationX}px`, `${spawnLocationY}px`];
    }

    function animate(timestamp) {
        if (!ref.current) return; // Stop if component has unmounted
        
        if (prevTime === undefined) {
            prevTime = timestamp;
        }
        if (timestamp - prevTime > interval) {
            const particle = document.createElement("div");
            particle.classList.add("sparkle");
            particle.onanimationend = () => particle.remove();
            [particle.style.left, particle.style.top] = getRandomSpawnLocation();
            ref.current.append(particle);
            prevTime = timestamp;
        }
        animationRef.current = window.requestAnimationFrame(animate);
    }

    useEffect(() => {
        animationRef.current = window.requestAnimationFrame(animate);

        // Cleanup function to cancel the animation on unmount
        return () => {
            if (animationRef.current) {
                window.cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);

    return <div ref={ref} className="bg"></div>;
}

const HeroSection = () => {

  const navigate = useNavigate();

  return (
    <div className="hero-section">
      <div className="hero-content">
      <Fade cascade damping={0.5}>
      <h1>
  Boostez Votre Entreprise avec nos <span className="highlight">Solutions Web</span>
</h1>
<p>
  Chez Techtide Solution, nous créons des sites web sur mesure pour donner vie à vos idées et renforcer votre présence en ligne. <br/>Des solutions simples, efficaces et adaptées pour vous aider à atteindre vos objectifs dans le monde digital.
</p>
<div className="hero-buttons">
  <button className="quote-button" onClick={() => navigate('/contact_us')}>Obtenir un Devis Gratuit</button> 
  <button className="start-button" onClick={() => navigate('/Pro')}>Lancer Votre Projet</button>
</div>
</Fade>
      </div>
      <AnimatedBackground />
    </div>
  );
};

export default HeroSection;
