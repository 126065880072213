import React from 'react';
import algerie from './algerie.jpg';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import Bilel_img from './Bilel.jpeg';
import Bilel_vcf from './Bilel_vcf.vcf';

const Bilel = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"55908406"}
            email={"7amidohanouna@gmail.com"}
            vcfFilePath={Bilel_vcf}
          />

          <BodyCarte
            firstname={"Bilel"}
            lastname={"Aouane"}
            title={""}
            address={"Cité El Intilaka"}
            phoneNumber={"55908406"}
            localPhoneNumber={""}
            email={"7amidohanouna@gmail.com"}
            website={""}
            imagePath={Bilel_img}
            Logo={algerie}
          />
        </>
    </div>
  );
};

export default Bilel;