import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import DaPietro_Resto_vcf from "./DaPietro_Resto_vcf.vcf"
import da_pietro_logo from "./da_pietro_logo.png"
import dapietro_bottom from "./dapietro_bottom.png"


const DaPietro_Resto = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+216 28 21 23 20"}
            email={"ahmed.hergal@gmail.com"}
            vcfFilePath={DaPietro_Resto_vcf}
          />

          <BodyCarte
            firstname={"Pizzeria"}
            lastname={"DaPietro"}
            title={"L’Antica Pizzeria DaPietro"}
            address={"L'antica Pizzeria DaPietro"}
            phoneNumber={"+216 29 56 09 99"}
            localPhoneNumber={"+216 28 21 23 20"}
            email={"ahmed.hergal@gmail.com"}
            website={"www.da-pietro.com"}
            imagePath={da_pietro_logo}
            Logo={dapietro_bottom}
            facebookHref="https://www.facebook.com/DaPietroHolding?mibextid=LQQJ4d"
            instagramHref="https://www.instagram.com/dapietro_tunisie/profilecard/?igsh=MXJtYzFsaXlxMzR0cg%3D%3D"
            linkedinHref="https://www.linkedin.com/company/dapietro/?trk=ppro_cprof&originalSubdomain=tn"
          />
        </>
    </div>
  );
};

export default DaPietro_Resto;