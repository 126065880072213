import React from 'react'
import "./web_contact.css"
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom';

const Web_contact = () => {

  const navigate = useNavigate();

  return (
    <div className='Web_contact'>
        <Fade cascade damping={0.5}>
        <div className='Web_contact_content'>
        <h1>Parlons de vos projets !</h1>
        <h2>Nous sommes fiers de notre capacité à performer et à obtenir des résultats. Utilisez le formulaire ci-dessous pour discuter de vos besoins avec notre équipe, nous vous répondrons dès que possible.</h2>
        <button onClick={() => navigate('/contact_us')}>Nous contacter</button> 
        </div>
        </Fade>
    </div>
  )
}

export default Web_contact