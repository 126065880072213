import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import logo_moon from "./logo_moon.png"
import rami_img from "./rami_img.jpg"
import Rami_Mtimet from "./Rami_Mtimet.vcf"

const RamiMtimet = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+216 21 04 58 85"}
            email={"rami.mtimet@hotmail.fr"}
            vcfFilePath={Rami_Mtimet}
          />

          <BodyCarte
            firstname={"Mtimet"}
            lastname={"Rami"}
            title={"CEO"}
            address={"24, Rue Khalij kamar ennaser 2"}
            phoneNumber={"+216 21 04 58 85"}
            localPhoneNumber={"+216 51 04 58 84"}
            email={"rami.mtimet@hotmail.fr"}
            website={"https://mooncoworking.space"}
            imagePath={rami_img}
            Logo={logo_moon}
            facebookHref="https://www.facebook.com/rami.mtimet/"
            instagramHref="https://www.instagram.com/rami.mtimet/"
            linkedinHref="https://www.linkedin.com/in/rami-mtimet/"
          />
        </>
    </div>
  );
};

export default RamiMtimet;