import React from 'react'
import QRCode from 'qrcode'
import { useState } from 'react'
import './style.css'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadIcon from '@mui/icons-material/Download';

const QrGenerator = () => {
	const [url, setUrl] = useState('')
	const [qr, setQr] = useState('')

	const GenerateQRCode = () => {
		QRCode.toDataURL(url, {
			width: 800,
			margin: 2,
			color: {
				dark: '#335383FF',
				light: '#EEEEEEFF'
			}
		}, (err, url) => {
			if (err) return console.error(err)

			console.log(url)
			setQr(url)
		})
	}

	return (
		<div className="QR">
			<h1>QR Generator</h1>
			<input 
				type="text"
				placeholder="e.g. https://google.com"
				value={url}
				onChange={e => setUrl(e.target.value)} />
			<button onClick={GenerateQRCode}>Generate <DownloadIcon/></button>
			{qr && <>
				<img alt='QR' src={qr} />
				<a href={qr} download="qrcode.png">Download</a>
                <DownloadDoneIcon style={{color:"#6bed85"}}/>
			</>}
		</div>
	)
}

export default QrGenerator