import React, { useState, useEffect } from 'react';
import './NavCarte.css';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import NewNavbar from '../NewNavbar/NewNavbar';

const NavCarte = ({ phoneNumber, email, vcfFilePath }) => {
    const navigate = useNavigate();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`; 
    };

    const handleSMSClick = () => {
        window.location.href = `sms:${phoneNumber}`; 
    };

    const handleDownload = () => {
        window.location.href = vcfFilePath; // Directly initiate download
    };

    return (
        <>
        <NewNavbar/>
        <div className={`navbar_carte ${isFixed ? 'fixed' : ''}`}>
            <div className='link' onClick={handleDownload}>
                <div className='save'>
                    <MoveToInboxIcon />
                    <h6>Enregistrer <br /> contact</h6>
                </div>
            </div>
            <div className='email_nav' onClick={handleEmailClick}>
                <EmailIcon />
                <h6>Envoyer <br /> E-mail</h6>
            </div>
            <div className='sms' onClick={handleSMSClick}>
                <MessageIcon />
                <h6>Envoyer <br /> SMS</h6>
            </div>
        </div>
    </>
    );
};

export default NavCarte; 
