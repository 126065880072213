import React from 'react';
import logo_olissey from './logo_olissey.png';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from "./BodyCarte";
import "./Carte.css"; 
import NBR from "./NBR.jpeg"
import Nadia_Boubaker_Rivet from "./Nadia_Boubaker_Rivet.vcf"

const NB = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+33609470749"}
            email={"nadia@olissey.com"}
            vcfFilePath={Nadia_Boubaker_Rivet}
          />

          <BodyCarte
            firstname={"Rivet Nadia"}
            lastname={"Boubaker"}
            title={"Co founder"}
            address={""}
            phoneNumber={"+33609470749"}
            localPhoneNumber={""}
            email={"nadia@olissey.com"}
            website={"https://olissey.com/"}
            imagePath={NBR}
            Logo={logo_olissey}
            facebookHref="https://www.facebook.com/Olisseyoliveoil/"
            instagramHref="https://www.instagram.com/olissey/"
            pinterestHref="https://www.pinterest.fr/MyOlissey/"
            linkedinHref="https://www.linkedin.com/in/nadia-rivet-3592a818a/"
          />
        </>
    </div>
  );
};

export default NB;