import React from 'react';
import footer_logo from '../../components/images/footer_logo.webp';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css";
import yassine from "./yassine.webp"
import yassine_vcf from './yassine_SOUSSI.vcf';

const Nizar = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"58636486"}
            email={"yassinesoussi@tuninnov.com"}
            vcfFilePath={yassine_vcf}
          />

          <BodyCarte
            firstname={"yassine"}
            lastname={"SOUSSI"}
            title={"CEO"}
            address={"Res. Hadayek Essada Jardin d'el Menzah"}
            phoneNumber={"53117700"}
            localPhoneNumber={"36215980"}
            email={"yassinesoussi@tuninnov.com"}
            website={"https://www.tuninnov.com"}
            imagePath={yassine}
            Logo={footer_logo}
          />
        </>
    </div>
  );
};

export default Nizar;