import React from 'react';
import fluidesConcept from '../../components/images/fluidesConcept.png';
import fluides from './fluides.jpeg';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import Mohamed_Sassi from "./Mohamed_Sassi.vcf"

const FluidesConcept = () => {

  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"23622398"}
            email={"fluidesconcept@topnet.tn"}
            vcfFilePath={Mohamed_Sassi}
          />

          <BodyCarte
            firstname={"Mohamed"}
            lastname={"Sassi"}
            title={"Gérant"}
            address={"25 Av. Taieb Mhiri, Ariana"}
            phoneNumber={"23622398"}
            localPhoneNumber={"71719257"}
            email={"fluidesconcept@topnet.tn"}
            website={""}
            imagePath={fluides}
            Logo={fluidesConcept}
          />
        </>
    </div>
  );
};

export default FluidesConcept;