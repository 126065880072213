import React from 'react';
import './Slider.css'; // Include the CSS file

const Slider = () => {
  return (
    <div className="slider">
      <div className="slide-track">
        <div className="slide"><img src="https://i.imgur.com/0LVqKXe.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/dBeY2zD.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/Uw90cwY.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/NBzERDq.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/mJX7bsC.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/MxufkrG.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/OBXe3UO.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/LNVZtfK.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/0LVqKXe.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/dBeY2zD.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/Uw90cwY.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/NBzERDq.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/mJX7bsC.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/MxufkrG.png" alt="" /></div>
        <div className="slide"><img src="https://i.imgur.com/OBXe3UO.png" alt="" /></div>
      </div>
    </div>
  );
};

export default Slider;
