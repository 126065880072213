import React from 'react';
import ContactLogo from '../../components/images/ContactLogo.png';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import nizar from './nizar.jpeg';
import nizar_vcf from './nizar_elmay.vcf';

const Nizar = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"99442060"}
            email={"nizar.may01@gmail.com"}
            vcfFilePath={nizar_vcf}
          />

          <BodyCarte
            firstname={"nizar"}
            lastname={"elmay"}
            title={"developer full stack js "}
            address={"14 rue habib bourguiba"}
            phoneNumber={"99442060"}
            localPhoneNumber={"71445566"}
            email={"nizar.may01@gmail.com"}
            website={"maygo.netlify.app"}
            imagePath={nizar}
            Logo={ContactLogo}
          />
        </>
    </div>
  );
};

export default Nizar;