import React, { useEffect } from 'react'
import "./Produits.css"
import NewNavbar from '../NewNavbar/NewNavbar';
import tunisie from "./tunisie.png"
import connexion from "./connexion.png"
import partage from "./partage.png"
import portefeuille from "./portefeuille.png"
import videoFile from"./videoFile.mp4"
import FooterDesktop from '../Footer/FooterDesktop';
import { useNavigate } from 'react-router-dom';

const MetalGoldCarte = () => {

  const navigate = useNavigate();

    const handleRedirect = () => {
        // Redirect to Google
        window.open("https://wa.me/21651013989?text=Carte de visite NFC - Metal Gold", "_blank"); 
      };

      useEffect(() => {
        // Scroll to top with smooth behavior when the component mounts
        window.scrollTo({ top: 0 });
      }, []);

    return (
        <div className="produit">
            <NewNavbar/>
        <div className="produit-container">
        <div className="produit-image">
        <img 
            src="https://i.imgur.com/p2mR2xe.jpeg" 
            alt="produit" 
          />
        </div>
          <div className="produit-content">
            <h1>Carte de visite NFC - Metal Gold</h1>
            <h2>109,900 DT Hors Taxe <img alt='tn' src={tunisie} /></h2>
            <button onClick={handleRedirect}> 🛒  Commander</button>
            <p>Les cartes de visite classiques appartiennent désormais au passé. Découvrez Tech-carte, 
                une solution NFC innovante conçue pour les professionnels d’aujourd’hui et les entreprises en plein essor.<br/>
                <br/>Conçue en acier et d’une couche de plastique recyclable, alliant élégance et innovation technologique. 
                Un atout majeur pour vos échanges professionnels.</p>
                <div>
                    <h3>Pourquoi choisir Tech-carte pour votre entreprise ?</h3>
                    <p>📲 <strong>Partagez vos informations :</strong> Transmettez vos coordonnées instantanément grâce au NFC ou à un QR code.</p>
                    <p>🔄 <strong>Modifiez vos données à tout moment :</strong> Profitez d’une gestion illimitée via un tableau de bord intuitif.</p>
                    <p>📇 <strong>Collectez les contacts de vos prospects :</strong> Enregistrez leurs informations directement grâce à votre Tech-carte.</p>
                    <p>😮 <strong>Faites forte impression :</strong> Apportez une touche de modernité et d’innovation à vos échanges.</p>
                    <p>🖨️ <strong>Dites adieu au papier :</strong> Adoptez une démarche plus écologique en abandonnant les cartes en papier.</p>
                    <p>🚀 <strong>Service rapide :</strong> Une fabrication et une livraison express pour répondre à vos besoins.</p>
                </div>
          </div>
        </div>
        <div className="order">
        <h1>Comment se déroule la commande ?</h1>
        <h3>Du choix de votre Tech-carte à sa livraison</h3>
        <div className="order-process">
      <div className="process-step">
        <div className="step-number">1</div>
        <div className="step-title">Choix du matériaux</div>
        <div className="step-description">
          Choisissez votre Tech-carte et la quantité en fonction du nombre de collaborateurs à équiper.
          <br />
          Il existe la PVC-classique, la eco+ , la carbone, la Metal Gold et la Metal Silver.
        </div>
      </div>

      <div className="process-step">
        <div className="step-number">2</div>
        <div className="step-title">Personnalisation</div>
        <div className="step-description">
          Vous recevez un lien pour envoyer vos informations (Nom, Prénom, logo, image, etc...). C'est notre équipe design qui s'occupera avec vous de la personnalisation.
        </div>
      </div>

      <div className="process-step">
        <div className="step-number">3</div>
        <div className="step-title">Livraison</div>
        <div className="step-description">
        Votre commande sera préparée avec soin et expédiée dans un délai de 4 à 5 jours ouvrables à compter de la date de passation. Nous mettons tout en œuvre pour vous garantir une livraison rapide et efficace.
        </div>
      </div>
    </div>
    </div>
    <div className="benefits-section">
  <h2>Les 3 bonnes raisons de choisir nos<br/>cartes NFC</h2>
  <p className="subtitle">
    Offrez à votre entreprise une solution moderne et écologique avec la technologie NFC.
  </p>
  <div className="benefits-container">
    <div className="benefit">
      <img src={portefeuille} alt="Économies" />
      <h3>Réduisez vos coûts</h3>
      <p>
        Avec Techtide Solutions, vous éliminez les frais d'impression et adoptez une solution numérique mise à jour en ligne gratuitement.
      </p>
    </div>
    <div className="benefit">
      <img src={connexion} alt="Développez votre réseau" />
      <h3>Renforcez vos connexions</h3>
       <p>
        Grâce à nos produits innovants, partager vos coordonnées devient un jeu d’enfant. Connectez-vous rapidement et efficacement avec vos contacts via la technologie NFC ou un simple QR code.
       </p>
    </div>
    <div className="benefit">
      <img src={partage} alt="Partagez vos infos" />
      <h3>Tout partager facilement</h3>
      <p>
        Envoyez vos coordonnées (informations professionnelles, personnelles, mais aussi vos comptes sur les réseaux sociaux ) en quelques secondes, sans utiliser de papier.
      </p>
    </div>
  </div>
  <video
        src={videoFile}
        autoPlay
        loop
        muted
        playsInline
        style={{ width: "100%"}}
      >
      </video>
      <div className='under_video'>
      <h1>Commandez votre Tech-carte personnalisée dès aujourd’hui</h1>
      <h3> Boostez votre réseau avec style et innovation et faites passer votre visibilité au niveau supérieur !</h3>
      <button onClick={handleRedirect}> 🛒  Commander</button>
      </div>
</div>
<div className='produits_similaires'>
        <h1>Produits similaires</h1>
        <div className='produits_similaires_container'>
        <div className='produits_similaires_part' onClick={() => navigate('/Carbone_Card')}>
          <img alt='produit_img' src='https://i.imgur.com/y7FI0qf.jpeg'/>
          <h3>Tech-carte carbone personnalisée</h3>
        </div>
        <div className='produits_similaires_part' onClick={() => navigate('/Metal_Silver')}>
          <img alt='produit_img' src='https://i.imgur.com/TO5OEta.jpeg'/>
          <h3>Tech-carte metal silver personnalisée</h3>
        </div>
        <div className='produits_similaires_part' onClick={() => navigate('/PVC_carte')}>
          <img alt='produit_img' src='https://i.imgur.com/O974Z7L.jpeg'/>
          <h3>Tech-carte PVC personnalisée</h3>
        </div>
        </div>
      </div>
      <FooterDesktop/>
        </div>
      );
    };

export default MetalGoldCarte