import React from 'react'
import './partie_5.css'
import { useNavigate } from 'react-router-dom';
import part5_pg from "./part5_bg.png"


const Partie5 = () => {
  const navigate = useNavigate();

  const ProSolutions = () => {
    navigate('/Pro');
  };
  return (
    <div>
        <div className="partie_5">
       <div className="partie_5_top">
  <div className="text-content5">
    <h1>NOS SOLUTION <br/> PROS</h1>
    <h2>Pour répondre à vos besoins</h2>
    <p>
    La technologie NFC offre de nombreuses possibilités et applications professionnelles et donc services
    que nous proposons afin de moderniser et apporter une touche de numérique au sein de votre société
    </p>
    <div className='button-container' onClick={ProSolutions}>
    <button>DÉCOUVRIR NOS SOLUTIONS</button>
    </div>
  </div>
  <div className="image-container5">
    <img className='img2' alt='img' src={part5_pg} />
  </div>
</div>
<div className='waves5'>
<img className='waves_1_5' alt='wave' src="https://i.imgur.com/7mGbD2K.png"/>
<img className='waves_4_5' alt='wave' src="https://i.imgur.com/uUPdeJG.png"/>
<img className='waves_2_5' alt='wave' src="https://i.imgur.com/B56PSYf.png"/>
<img className='waves_3_5' alt='wave' src="https://i.imgur.com/Q7o01CX.png"/>

</div>
    </div>
    </div>
  )
}

export default Partie5