import React, { useState } from 'react'
import "./CartePerso.css"
import { useNavigate } from 'react-router-dom'
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const CartePerso = () => {

  const navigate = useNavigate();

  const contactClick = () => {
      navigate('/contact_us');
    };

    const homeClick = () => {
      navigate('/');
    };

    const LaNFC = () => {
      navigate('/LaNFC');
      setDrawerOpen(false);
    };
  
    const ProSolutions = () => {
      navigate('/Pro');
      setDrawerOpen(false);
    };
  
    const FAQ = () => {
      navigate('/FAQ');
      setDrawerOpen(false);
    };
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleMenuClick = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const list = (
    <List>
      <CloseIcon style={{color:"#CF4D9C",fontSize:"40px",marginLeft:"5px"}} onClick={handleCloseDrawer}/>
      <ListItem disablePadding>
        <ListItemButton component="a" onClick={homeClick}>
          <ListItemIcon>
            <img src="https://i.imgur.com/q43R2c3.png" alt='img' className='img_drawer'/>
          </ListItemIcon>
          <ListItemText primary="Acceuil" style={{color:"#14A5BC"}} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component="a" onClick={LaNFC}>
          <ListItemIcon>
            <img src="https://i.imgur.com/mBz68WZ.png" alt='img' className='img_drawer'/>
          </ListItemIcon>
          <ListItemText primary="La NFC" style={{color:"#14A5BC"}} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component="a" onClick={ProSolutions}>
          <ListItemIcon>
            <img src="https://i.imgur.com/ykXcBCy.png" alt='img' className='img_drawer'/>
          </ListItemIcon>
          <ListItemText primary="Nos Solutions Pro" style={{color:"#CF4D9C"}} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component="a" onClick={contactClick}>
          <ListItemIcon>
          <img src="https://i.imgur.com/4vuWaef.png" alt='img' className='img_drawer'/>
          </ListItemIcon>
          <ListItemText primary="Nous Contacter" style={{color:"#14A5BC"}}/>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component="a" onClick={FAQ}>
          <ListItemIcon>
          <img src="https://i.imgur.com/G9zLpYe.png" alt='img' className='img_drawer'/>
          </ListItemIcon>
          <ListItemText primary="Qestions Courantes" style={{color:"#14A5BC"}}/>
        </ListItemButton>
      </ListItem>
    </List>
  );
  
  return (
    <div className='CartePerso'>
        <img alt='hamburger' src="https://i.imgur.com/we1M24r.png" className='hamburger' onClick={handleMenuClick}/>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleCloseDrawer} 
          PaperProps={{
            sx: {
              width: 240,
              height: 330,
              marginTop:3,
              borderTopRightRadius:15,
              borderBottomRightRadius:15,
              background:"#f8f8f8"
            }
          }}
        >
          {list}
        </Drawer>
        <div className='Perso_Profile_Pic_container'>
          <img alt='profile_pic'src="https://i.imgur.com/owuLJqD.jpeg" className='Perso_Profile_Pic'/>
        </div>
        <h1>Foulena BenFoulen</h1>
        <div className='perso_icons_container'>
          <img alt='profile_icon'src="https://i.imgur.com/S77NVbG.png"/>
          <img alt='profile_icon'src="https://i.imgur.com/1w3ChzY.png"/>
          <img alt='profile_icon'src="https://i.imgur.com/jlxZ8F9.png"/>
          <img alt='profile_icon'src="https://i.imgur.com/2c7qNSB.png"/>
          <img alt='profile_icon'src="https://i.imgur.com/HEer4I9.png"/>
        </div>
        <div className='perso_buttons_container'>
          <button className='perso_btn'>Enregister Contact</button>
          <button className='perso_btn'>Nouveautées</button>
          <button className='perso_btn'>Liens Privées</button>
          <button className='perso_btn'>Chaine Youtube</button>
          <button className='perso_btn'>Loisirs</button>
        </div>
        <div className='perso_logo_container'>
        <img alt='Logo' src="https://i.imgur.com/Mi0d2ny.png" className='perso_logo'/>
        </div>
    </div>
  )
}

export default CartePerso