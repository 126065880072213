import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQ.css'
import Slide from 'react-reveal/Slide'; 

export default function BasicAccordion() {
  return (
    <div id='FAQ'>
        
        <Slide top>
        <h1 id='title'>Questions fréquemment posées</h1>
        </Slide>
        <div className='FAQ_container'>
        <Slide bottom>
        <Accordion style={{marginBottom:"2%",width:"100%",backgroundColor:" rgb(250, 250, 250)",color:"rgb(80, 80, 80)",borderBottomColor:"#3C88BB",borderBottomStyle:"solid"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{fontFamily:"Segoe UI",color:"noir",fontSize:"16px",fontWeight:"500"}}>Qu'est-ce qu'une carte de visite NFC et comment fonctionne-t-elle ?</Typography>
        </AccordionSummary>
        <AccordionDetails className='typo'>
          <Typography style={{textAlign:"left"}}>
          Une carte de visite NFC est une carte traditionnelle intégrant une puce NFC. Elle fonctionne en
           permettant la communication sans fil à courte portée avec des smartphones ou d'autres appareils compatibles NFC.
          </Typography>
        </AccordionDetails>
      </Accordion>
        </Slide>

        <Slide bottom>
        <Accordion style={{marginBottom:"2%",backgroundColor:" rgb(250, 250, 250)",color:"rgb(80, 80, 80)",borderBottomColor:"#3C88BB",borderBottomStyle:"solid"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{fontFamily:"Segoe UI",color:"noir",fontSize:"16px",fontWeight:"500"}}>Pourquoi devrais-je choisir une carte de visite NFC ?</Typography>
        </AccordionSummary>
        <AccordionDetails className='typo'>
          <Typography style={{textAlign:"left"}}>
          Les cartes de visite NFC offrent une approche moderne et interactive pour partager des informations professionnelles.
           Elles permettent aux contacts de simplement approcher leur smartphone pour recevoir vos informations, 
           facilitant ainsi le partage des coordonnées.
          </Typography>
        </AccordionDetails>
      </Accordion>
        </Slide>

        <Slide bottom>
        <Accordion style={{marginBottom:"2%",backgroundColor:" rgb(250, 250, 250)",color:"rgb(80, 80, 80)",borderBottomColor:"#3C88BB",borderBottomStyle:"solid"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{fontFamily:"Segoe UI",color:"noir",fontSize:"16px",fontWeight:"500"}}>Comment puis-je lire une carte de visite NFC avec mon smartphone ?</Typography>
        </AccordionSummary>
        <AccordionDetails className='typo'>
          <Typography style={{textAlign:"left"}}>
          Lire une carte de visite NFC est simple. 
          Approchez simplement la carte du dos de votre smartphone, où la puce NFC est généralement située.
          </Typography>
        </AccordionDetails>
      </Accordion>
        </Slide>

        <Slide bottom>
        <Accordion style={{marginBottom:"2%",backgroundColor:" rgb(250, 250, 250)",color:"rgb(80, 80, 80)",borderBottomColor:"#3C88BB",borderBottomStyle:"solid"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{fontFamily:"Segoe UI",color:"noir",fontSize:"16px",fontWeight:"500"}}>Comment puis-je commander ma carte de visite NFC ?</Typography>
        </AccordionSummary>
        <AccordionDetails className='typo'>
          <Typography style={{textAlign:"left"}}>
          Pour commander votre carte de visite NFC, visitez notre site web www.TechTide.com, remplissez le formulaire dans la page Nous Contacter.
          </Typography>
        </AccordionDetails>
      </Accordion>
        </Slide>

        <Slide bottom>
        <Accordion style={{marginBottom:"2%",backgroundColor:" rgb(250, 250, 250)",color:"rgb(80, 80, 80)",borderBottomColor:"#3C88BB",borderBottomStyle:"solid"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{fontFamily:"Segoe UI",color:"noir",fontSize:"16px",fontWeight:"500"}}>Les cartes de visite NFC de TECHTIDE sont-elles sécurisées ?</Typography>
        </AccordionSummary>
        <AccordionDetails className='typo'>
          <Typography style={{textAlign:"left"}}>
          Oui, nous prenons la sécurité au sérieux. 
          Les cartes de visite NFC de TECHTIDE offrent des fonctionnalités de sécurité pour protéger vos informations professionnelles.
          </Typography>
        </AccordionDetails>
      </Accordion>
        </Slide>

        <Slide bottom>
        <Accordion style={{marginBottom:"2%",backgroundColor:" rgb(250, 250, 250)",color:"rgb(80, 80, 80)",borderBottomColor:"#3C88BB",borderBottomStyle:"solid"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{fontFamily:"Segoe UI",color:"noir",fontSize:"16px",fontWeight:"500"}}>Est-ce que TECHTIDE propose des options de personnalisation ?</Typography>
        </AccordionSummary>
        <AccordionDetails className='typo'>
          <Typography style={{textAlign:"left"}}>
          Oui, TECHTIDE offre des options de personnalisation. Vous pouvez choisir parmi différents designs, couleurs, et formats. 
          Vous pouvez également nous envoyer votre propre design si vous avez déjà une mise en page spécifique en tête.
          </Typography>
        </AccordionDetails>
      </Accordion>
        </Slide>
        </div>
    </div>
  );
}