import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import monia_img from './monia_img.jpg';
import Monia_Fekih from './Monia_Fekih.vcf';
import gastro from './gastro.jpg';

const MoniaFekih = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"98262260"}
            email={"nizar.may01@gmail.com"}
            vcfFilePath={Monia_Fekih}
          />

          <BodyCarte
            firstname={"Monia"}
            lastname={"Fekih"}
            title={"Chef de service à Hôpital la Rabta"}
            address={"1 Rue Ibn khaldoun ,1004 ,Tunis"}
            phoneNumber={"98262260"}
            localPhoneNumber={"71560522"}
            email={"fekihmonia@yahoo.fr"}
            website={""}
            imagePath={monia_img}
            Logo={gastro}
          />
        </>
    </div>
  );
};

export default MoniaFekih;