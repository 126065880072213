import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import test_pic from './test_pic.png';
import test_logo from './test_logo.jpg';

const Test = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+216 12 34 56 78"}
            email={"foulenBenFOULEN@gmail.com"}
          />

          <BodyCarte
            firstname={"Foulen"}
            lastname={"BenFOULEN"}
            title={"Directeur General"}
            address={"Avenue Habib Bourguiba, Tunis"}
            phoneNumber={"+216 12 34 56 78"}
            localPhoneNumber={"+216 71 22 44 66"}
            email={"foulenBenFOULEN@gmail.com"}
            website={""}
            imagePath={test_pic}
            Logo={test_logo}
          />
        </>
    </div>
  );
};

export default Test;