import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import skander_img from './skander_img.jpg';
import Skander_vcf from './Skander_vcf.vcf';
import chef from './chef.png';

const Skander = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"55328240"}
            email={"skanderfettah6@gmail.com"}
            vcfFilePath={Skander_vcf}
          />

          <BodyCarte
            firstname={"skander"}
            lastname={"Fettah"}
            title={"Gestionnaire en restauration"}
            address={"Avenue Alain Savary, Tunis"}
            phoneNumber={"55328240"}
            localPhoneNumber={""}
            email={"skanderfettah6@gmail.com"}
            website={""}
            imagePath={skander_img}
            Logo={chef}
          />
        </>
    </div>
  );
};

export default Skander;