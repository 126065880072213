import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'; 
import BasicAccordion from '../Home/FAQ/FAQ';
import "./style.css"
import NewNavbar from '../NewNavbar/NewNavbar';
import FooterDesktop from '../Footer/FooterDesktop';

const FAQ_page = () => {
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
        <NewNavbar/>
        <div className="partie_1_faq">
      <div className="partie_1_top_faq">
        <div className="text-content_faq">
        <Fade left>
            <img alt='faq' src="https://i.imgur.com/cH3KU3R.png" className='faq_icon'/>
            <h1>Bienvenue dans notre section FAQ ! Ici, 
            vous trouverez des réponses aux questions courantes sur nos produits/services.<br/> Si vous ne trouvez pas ce que vous cherchez,
            n'hésitez pas à nous contacter pour obtenir de l'aide.</h1>
        </Fade>
        </div>
        <div className="image-container_faq">
        <img className='faq_img' alt='img' src="https://i.imgur.com/5ER5hq8.png" />
        </div>
      </div>
      <div className='waves_faq' >
        <img className='waves_1_1_faq' alt='wave' src="https://i.imgur.com/Oig6sk4.png"/>
        <img className='waves_4_1_faq' alt='wave' src="https://i.imgur.com/pJmoCWl.png"/>
        <img className='waves_2_1_faq' alt='wave' src="https://i.imgur.com/7CkUIQz.png"/>
        <img className='waves_3_1_faq' alt='wave' src="https://i.imgur.com/qQeWilT.png"/>
      </div>
    </div>
    <BasicAccordion/>
    <FooterDesktop/>
    </div>
  )
}

export default FAQ_page