import React, { useEffect } from 'react'
import HeroSection from './Web_page1/HeroSection'
import NewNavbar from '../NewNavbar/NewNavbar'
import Services from './Web_page2/Web_page2'
import Web_page3 from './Web_page3/Web_page3'
import Web_contact from './Web_page3/Web_contact'
import FooterDesktop from '../Footer/FooterDesktop'
const Web_page = () => {

  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
        <NewNavbar/>
        <HeroSection/>
        <Services/>
        <Web_page3/>
        <Web_contact/>
        <FooterDesktop/>
    </div>
  )
}

export default Web_page