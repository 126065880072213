import React, { useState, useRef, useEffect } from 'react';
import "./VcfForm.css";
import NewNavbar from '../NewNavbar/NewNavbar';
import FooterDesktop from '../Footer/FooterDesktop';
import smartphone from "./smartphone.png"
import emailjs from '@emailjs/browser';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import CloudinaryUploadWidget from './CloudinaryUploadWidget'; 
import { useNavigate } from 'react-router-dom';
import './Create.css';

const VcfForm = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [showWelcomePopup, setShowWelcomePopup] = useState(true);
  const [vcfCreated, setVCFCreated] = useState(false);
  const form = useRef();
  const navigate = useNavigate();

  const handleStartClick = () => {
    setShowWelcomePopup(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    prefix: '',
    suffix: '',
    nickname: '',
    photo: '',
    birthday: '',
    gender: '',
    address_home: '',
    address_work: '',
    address_other: '',
    phone_home: '',
    phone_work: '',
    phone_fax: '',
    phone_cell: '',
    phone_other: '',
    email_home: '',
    email_work: '',
    email_other: '',
    organization: '',
    title: '',
    role: '',
    website: '',
    social_facebook: '',
    social_twitter: '',
    social_linkedin: '',
    social_instagram: '',
    social_other: '',
    notes: 'powered bu TECHTIDE',
  });

  // Set Cloudinary credentials
  const cloudName = 'dve01caoc';
  const uploadPreset = 'qgirte5g';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  const handleGenderSelection = (gender) => {
    setFormData({ ...formData, gender }); 
};

const handlePrefix = (prefix) => {
  setFormData({ ...formData, prefix }); 
};

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_x9apz2y', 'template_pcuc3rc', form.current, 'PerSxUcVtQx41qWk4')
      .then(
        () => {
          console.log('SUCCESS!');
          setVCFCreated(true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );

    // Reset the form inputs
    setFormData({
      firstname: '',
      lastname: '',
      prefix: '',
      suffix: '',
      nickname: '',
      photo: '',
      birthday: '',
      gender: '',
      address_home: '',
      address_work: '',
      address_other: '',
      phone_home: '',
      phone_work: '',
      phone_fax: '',
      phone_cell: '',
      phone_other: '',
      email_home: '',
      email_work: '',
      email_other: '',
      organization: '',
      title: '',
      role: '',
      website: '',
      social_facebook: '',
      social_twitter: '',
      social_linkedin: '',
      social_instagram: '',
      social_other: '',
      notes: '',
    });
  };

  return (
    <div className='VcfForm'>
      <NewNavbar/>
      {showWelcomePopup ? (
        <div className='welcome_popup'>
          <div className='welcome_popup_container'>
            <h1 className='welcome_popup_h1'>Créer mon profil en ligne</h1>
            <p className='welcome_popup_p'>
              Plongez dans l’expérience unique d’une carte visite virtuelle et accédez
              au formulaire de création de votre profil en ligne
            </p>
            <button 
              className='welcome_popup_button'
              onClick={handleStartClick}
            >
              Commencer
            </button>
          </div>
        </div>
      ) : (
        vcfCreated ? (
          <div className='welcome_popup'>
            <div className='welcome_popup_container'>
              <h1 className='welcome_popup_h1'>Félicitations</h1>
              <p className='welcome_popup_p'>
  Merci pour votre confiance ! Nous avons bien reçu vos informations et vous recontacterons dès que votre produit et page sont prêts.
</p>

              <button 
                className='welcome_popup_button'
                onClick={() => navigate('/')}
                >
                Acceuil
              </button>

            </div>
          </div>
        ) : (
          <div className='VCF_Page'>
            <div className='VCF_Page_part1'>
              <div className='VCF_Page_part1_content'>
                <h2>simple - rapide - économique</h2>
                <h1>Créer Votre Page en 5 Minutes</h1>
                <p>Vous pourrez modifier vos informations à tout moment.</p>
              </div>
              <div className='VCF_Page_part1_image'>
                <img alt='img' src={smartphone}/>
              </div>
            </div>
            <div className='VCF_Page_part2'>
              <div className='form_vcf'>
                <form ref={form} className='VCFForm' onSubmit={sendEmail}>
                <label className='phone_label'>Nom & Prénom</label>
                  <div className='VCFForm_part1'>
                    <div>
                  <div className="gender-selection">
                  <button
                    type="button"
                    className={`gender-button ${formData.gender === 'Homme' ? 'selected' : ''}`}
                    onClick={() => handleGenderSelection('Homme')}
                  >
                    Homme
                  </button>
                  <button
                    type="button"
                    className={`gender-button ${formData.gender === 'Femme' ? 'selected' : ''}`}
                    onClick={() => handleGenderSelection('Femme')}
                  >
                    Femme
                  </button>
                </div>
                <input
                type="text"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                style={{display:"none"}}
            />
                  <div className="button-group">
                  <div className="button-group1">Préfixe</div>
                  <div className="button-group2">
                    <button
                      type="button"
                      className={`prefix-button ${formData.prefix === 'Mr' ? 'selected' : ''}`}
                      onClick={() => handlePrefix('Mr')}
                    >
                      Mr
                    </button>
                    <button
                      type="button"
                      className={`prefix-button ${formData.prefix === 'Mme' ? 'selected' : ''}`}
                      onClick={() => handlePrefix('Mme')}
                    >
                      Mme
                    </button>
                    <button
                      type="button"
                      className={`prefix-button ${formData.prefix === 'Dr' ? 'selected' : ''}`}
                      onClick={() => handlePrefix('Dr')}
                    >
                      Dr
                    </button>
                    <button
                      type="button"
                      className={`prefix-button ${formData.prefix === 'Pr' ? 'selected' : ''}`}
                      onClick={() => handlePrefix('Pr')}
                    >
                      Pr
                    </button>
                  </div>
                </div>
                <input
                type="text"
                name="prefix"
                value={formData.prefix}
                onChange={handleChange}
                style={{display:"none"}}
            />
                    <input type="date" name="birthday" value={formData.birthday} onChange={handleChange} placeholder="Birthday" />
                    </div>
                    <div>
                    <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} placeholder="Nom" />
                    <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} placeholder="Prenom" />
                  <input type="text" name="nickname" value={formData.nickname} onChange={handleChange} placeholder="Pseudo" />
                    </div>
                  </div>
                  <label className='phone_label'>Photo</label>
                  <CloudinaryUploadWidget
                    uwConfig={{ cloudName, uploadPreset }}
                    setPublicId={(url) => setFormData({ ...formData, photo: url })}
                  />
                  {formData.photo && (
                    <div style={{ width: '180px', height: '180px', overflow: 'hidden', borderRadius: '50%', border: '1px solid #ccc', marginTop: "5%" }}>
                      <img src={formData.photo} alt="Uploaded" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                  )}
                <input
                type="text"
                name="photo"
                value={formData.photo}
                onChange={handleChange}
                style={{display:"none"}}
            />
                  <div className='VCFForm_part2'>
                  <label className='phone_label'>Entreprise</label>
                  <div className='Entreprise_container'>
                    <div>
                    <input type="text" name="organization" value={formData.organization} onChange={handleChange} placeholder="Nom de l'Entreprise" />
                    <input type="text" name="title" value={formData.title} onChange={handleChange} placeholder="Poste" />
                    </div>
                    <div>
                    <input type="text" name="role" value={formData.role} onChange={handleChange} placeholder="Département" />
                    <input type="text" name="website" value={formData.website} onChange={handleChange} placeholder="Lien Site Web" />
                    </div>
                  </div>
                  <div className='phone_label_container'>
                    <label className='phone_label'>Num Personnel</label>
                    <PhoneInput
                      defaultCountry="tn"
                      name="phone_cell"
                      value={formData.phone_cell}
                      onChange={(value) => handleChange({ target: { name: 'phone_cell', value } })}
                    />
                  </div>
                  <div className='phone_label_container'>
                    <label className='phone_label'>Num fixe</label>
                    <PhoneInput
                      defaultCountry="tn"
                      name="phone_home"
                      value={formData.phone_home}
                      onChange={(value) => handleChange({ target: { name: 'phone_home', value } })}
                    />
                  </div>
                  <div className='phone_label_container'>
                    <label className='phone_label'>Num Professionnel</label>
                    <PhoneInput
                      defaultCountry="tn"
                      name="phone_work"
                      value={formData.phone_work}
                      onChange={(value) => handleChange({ target: { name: 'phone_work', value } })}
                    />
                  </div>
                  <div className='phone_label_container'>
                    <label className='phone_label'>Num Pro Fixe</label>
                    <PhoneInput
                      defaultCountry="tn"
                      name="phone_fax"
                      value={formData.phone_fax}
                      onChange={(value) => handleChange({ target: { name: 'phone_fax', value } })}
                    />
                  </div>
                  <label className='phone_label'>Adresse</label>
                  <input type="text" name="address_home" value={formData.address_home} onChange={handleChange} placeholder="Adresse Personnelle" />
                  <input type="text" name="address_work" value={formData.address_work} onChange={handleChange} placeholder="Adresse Bureau" />
                  <input type="text" name="address_other" value={formData.address_other} onChange={handleChange} placeholder="Autre Adresse" />
                  <label className='phone_label'>Email</label>
                  <input type="email" name="email_home" value={formData.email_home} onChange={handleChange} placeholder="Email Personnel" />
                  <input type="email" name="email_work" value={formData.email_work} onChange={handleChange} placeholder="Email Bureau" />
                  <input type="email" name="email_other" value={formData.email_other} onChange={handleChange} placeholder="Autre Email" />
                  <label className='phone_label'>Réseaux Sociaux</label>
                  <input type="text" name="social_facebook" value={formData.social_facebook} onChange={handleChange} placeholder="Facebook" />
                  <input type="text" name="social_linkedin" value={formData.social_linkedin} onChange={handleChange} placeholder="LinkedIn" />
                  <input type="text" name="social_instagram" value={formData.social_instagram} onChange={handleChange} placeholder="Instagram" />
                  <input type="text" name="social_twitter" value={formData.social_twitter} onChange={handleChange} placeholder="Twitter" />
                  <input type="text" name="social_other" value={formData.social_other} onChange={handleChange} placeholder="Autre Réseaux Sociaux" />
                  </div>
                  <button className='welcome_popup_button' type="submit">Envoyer</button>
                </form>
              </div>
            </div>
          </div>
        )
      )}
      <FooterDesktop/>
    </div>
  );
};

export default VcfForm;
