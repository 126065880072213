import React, { useEffect } from 'react';
import "./style.css"
import NewNavbar from '../NewNavbar/NewNavbar'
import FooterDesktop from '../Footer/FooterDesktop'
import HomeImg from "./HomeImg.png"
import sac from "./sac.png"
import pro from "./pro.png"
import bouclier from "./bouclier.png"
import csr from "./csr.png"
import rapide from "./rapide.png"
import carte from "./carte.png"
import google from "./google.png"
import Slider from './Slider'
import img3_1 from './img3_1.png'
import IOS from './IOS.png'
import cards from './cards.png'
import impression from './impression.png'
import Partie5 from '../Home/Partie_5/Partie_5'
import BasicAccordion from '../Home/FAQ/FAQ'
import { useNavigate } from 'react-router-dom'


const HomeFinal = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
              <NewNavbar />
              <div className='HomeFinal'> 
              <div className='HomeFinal1'>
                <div className='HomeFinal1_text'>
                    <h3>SIMPLE - RAPIDE - ECONOMIQUE</h3>
                    <h1>La Carte Visite réinventée.</h1>
                    <h2>Qui va révolutionner vos prises de contact à jamais</h2>
                    <div className='HomeFinal1_btn'>
                        <button className='firstbtn' onClick={() => navigate('/contact_us')}> <img alt='icon' src={sac}/>commander</button>
                        <button className='secondbtn' onClick={() => navigate('/Pro')}> <img alt='icon' src={pro}/>pour les pros</button></div>
                </div>
                <div className='HomeFinal1_img'><img alt='homeImg' src={HomeImg}/></div>
              </div>
              <div className='HomeFinal2'>
                <div className='HomeFinal2_1'>
                    <img alt='icon' src={bouclier}/>
                    <div>
                        <h1>Sécurité & RGPD</h1>
                        <p>TECHTIDE est audité et audité et totalement conforme au RGPD pour une protection maximale 
                          de vos données personnelles</p>
                    </div>
                </div>
                <div className='HomeFinal2_1'>
                    <img alt='icon' src={rapide}/>
                    <div>
                        <h1>Simple & Rapide</h1>
                        <p>Ce sont nos points d'honneur afin de vous offrir la meilleure expérience 
                          possible dans votre quête de nouvelles technologies</p>
                    </div>
                </div>
                <div className='HomeFinal2_1'>
                    <img alt='icon' src={csr}/>
                    <div>
                        <h1>RSE & Ecologie</h1>
                        <p>Nous adoptons une approche éco-resonable en minimisant notre 
                          empreinte carbone et en favorisant les matériaux durables et recyclables</p>
                    </div>
                </div>
                <div className='HomeFinal2_1'>
                    <img alt='icon' src={carte}/>
                    <div>
                        <h1>Fabriqué en Tunisie</h1>
                        <p>Nos produits sont conçus et fabriqès en Tunisie. Cela nous 
                          permet de garantir une qualité supérieure et des délais de fabrication réduits</p>
                    </div>
                </div>
              </div>
              <div className='waves'>
        <img className='waves_1_1' loading="lazy" alt='wave' src="https://i.imgur.com/Oig6sk4.png" style={{ animation: 'waveAnimation1 2s ease-in-out alternate infinite' }}/>
        <img className='waves_4_1' loading="lazy" alt='wave' src="https://i.imgur.com/pJmoCWl.png" style={{ animation: 'waveAnimation4 2s ease-in-out alternate infinite' }}/>
        <img className='waves_2_1' loading="lazy" alt='wave' src="https://i.imgur.com/7CkUIQz.png" style={{ animation: 'waveAnimation2 2s ease-in-out alternate infinite' }}/>
        <img className='waves_3_1' loading="lazy" alt='wave' src="https://i.imgur.com/qQeWilT.png" style={{ animation: 'waveAnimation3 2s ease-in-out alternate infinite' }}/>
      </div>
      </div>
      <div className='Confiance'>
        <div className='Confiance_content'> 
            <h1>Ces Entreprises nous ont fait confiance</h1>
            <h2>en optant pour nos cartes et solutions NFC pour eux mêmes et leur collaborateurs</h2>
            <img alt='img' src={google}/>
        </div>
        <Slider />
        <div className="partnership-container">
      <h2>Notre Nouveau Partenariat en Algérie</h2>
      <p className="announcement-text">
        Nous sommes ravis d'annoncer notre partenariat stratégique avec<br/> <strong>EURL Bourassia Services </strong> 
        spécialisée dans le secteur de la distribution et fourniture en Commerce multiple., 
        notre représentant légal en Algérie. Ensemble, nous nous engageons à fournir des solutions innovantes 
        et à renforcer notre présence dans la région.
      </p>

      <div className="partner-details">
        <img src="https://i.imgur.com/ZXc5jSA.png" alt='Logo de EBS' className="partner-logo" />
        <div className="partner-info">
          <h3>EURL Bourassia Services</h3>
          <p><strong>Adresse : </strong> 22 rue des frères Abdeslam kouba alger</p>
          <p><strong>Téléphone : </strong> +213 552 70 39 53</p>
          <p><strong>Email : </strong> sakoudaahmed@gmail.com</p>
        </div>
      </div>
      </div>
      </div>
      <div className='HomeFinal3'>
        <div className='HomeFinal3_1'>
        <h1>Il est temps de transformer <span style={{color:"rgb(140, 88, 200)"}}>vos rencontres professionnelles !</span></h1>
        <h2>Grâce à nos solutions adaptées et innovantes qui feront forte impression</h2>
        </div>
        <div className='HomeFinal3_2'>
          <div className='HomeFinal3_2_content'>
            <h1>Pourquoi la carte de visite NFC va révolutionner votre vie professionnelle ?</h1>
            <p>Grâce à la technologie Near Fiel Communication (NFC) la carte connectée est reliée à votre profil digital en ligne. 
              Ce profil est modifiable gratuitement et sans limite.
            </p>
            <button onClick={() => navigate('/contact_us')}>Commander votre carte !</button>
          </div>
          <div className='HomeFinal3_2_img'>
            <img alt='img' src={img3_1}/>
          </div>
        </div>
        <div className='HomeFinal3_2_bottom'>
            <div className='HomeFinal3_2_bottom_content'>
              <div>
                <img alt='img' src={IOS}/>
              </div>
              <h1>1 - Rapide, Simple, Universel</h1>
              <p>Les cartes NFC fonctionnet avec IOS & Android sans avoir besoin d'application. 
                Vous badgez en sans-contact ou scannez le Qr Code</p>
            </div>
            <div className='HomeFinal3_2_bottom_content'>
              <div>
                <img alt='img' src={cards}/>
              </div>
              <h1>2 - Economies Importantes</h1>
              <p>Une seule carte visite NFC équivaut à 100 000 cartes visites classiques, et contiennent bien plus d'informations
                Une seule carte c'est pour la vie.
              </p>
            </div>
            <div className='HomeFinal3_2_bottom_content'>
              <div>
                <img alt='img' src={impression}/>
              </div>
              <h1>3 - Personnalisez à 100%</h1>
              <p>On vous propose une personalisation totale de vos cartes NFC et de
                 vos pages web avec la possibilté d'une mise à jour de vos informations à tout moment.</p>
            </div>
          </div>
      </div>
      <Partie5/>
      <div class="popular-products">
  <h2>Les plus demander </h2>
  <div class="products-container">
    <div class="product-card" onClick={() => navigate('/Carbone_Card')}>
      <img src="https://i.imgur.com/y7FI0qf.jpeg" alt="" />
      <p class="product-name">Carte de visite NFC - Carbone</p>
      <p class="product-price">49,900 DT Hors Taxe</p>
    </div>
    <div class="product-card" onClick={() => navigate('/TripAdvisor_card')}>
      <img src="https://i.imgur.com/q9oKCw1.jpeg" alt="" />
      <p class="product-name">Carte NFC Avis Tripadvisor</p>
      <p class="product-price">27.700 DT Hors Taxe</p>
    </div>
    <div class="product-card" onClick={() => navigate('/Metal_Gold')}>
      <img src="https://i.imgur.com/p2mR2xe.jpeg" alt="" />
      <p class="product-name">Tech-carte metal Gold personnalisée</p>
      <p class="product-price">109,900 DT Hors Taxe</p>
    </div>
    <div class="product-card" onClick={() => navigate('/ECO+_carte')}>
      <img src="https://i.imgur.com/uHwOcl6.jpeg" alt="" />
      <p class="product-name">Carte de visite NFC - Eco+</p>
      <p class="product-price">34,700 DT Hors Taxe</p>
    </div>
  </div>
</div>

      <BasicAccordion/>
              <div className='HomeFinal_footer'>
              <FooterDesktop/>
              </div>
    </div>
  )
}

export default HomeFinal