import React, { useRef, useEffect, useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import powered_by from '../../components/images/powered_by.png'
import { useNavigate } from 'react-router-dom';
import Bounce from 'react-reveal/Bounce';
import "./BodyCarte.css"
import { Fade } from 'react-reveal';

const BodyCarte = ({ firstname, lastname, title, address, phoneNumber, localPhoneNumber, email, website, imagePath, Logo, facebookHref, instagramHref, youtubeHref, pinterestHref, linkedinHref }) => {

    const navigate = useNavigate();

    const ContactClick = () => {
        navigate('/contact_us');
      };

    const [isMapFieldClicked, setIsMapFieldClicked] = useState(false);
    const [isPhoneCallClicked, setIsPhoneCallClicked] = useState(false);
    const [isLocalPhoneCallClicked, setIsLocalPhoneCallClicked] = useState(false);
    const [isEmailClicked, setIsEmailClicked] = useState(false);
    const [isWebsiteClicked, setIsWebsiteClicked] = useState(false);
  
    const handleMapClick = () => {
      const formattedAddress = address.replace(/ /g, '+');
    
      const mapUri = `geo:0,0?q=${formattedAddress}`;
      
      setIsMapFieldClicked(true);
      
      window.location.href = mapUri;
    };

      const handlePhoneCall = () => {
        window.location.href = `tel:${phoneNumber}`;
        setIsPhoneCallClicked(true);
      };

      const handleLocalPhoneCall = () => {
        window.location.href = `tel:${localPhoneNumber}`;
        setIsLocalPhoneCallClicked(true);
      };

      const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
        setIsEmailClicked(true);
      };

      const handleWebsiteClick = () => {
        window.open(website, '_blank');
        setIsWebsiteClicked(true);
      };
      
      const profileNameRef = useRef(null);
      const [fontSize, setFontSize] = useState(2);
    
      useEffect(() => {
        const adjustFontSize = () => {
          if (profileNameRef.current) {
            const containerWidth = profileNameRef.current.offsetWidth;
            const textWidth = profileNameRef.current.scrollWidth;
    
            const ratio = containerWidth / textWidth;
    
            const newFontSize = fontSize * ratio;
            setFontSize(newFontSize);
          }
        };
    
        adjustFontSize();
    
        window.addEventListener('resize', adjustFontSize);
    
        return () => {
          window.removeEventListener('resize', adjustFontSize);
        };
      }, [fontSize]);

  return (
    <div className="cardBody_scit">
        <div className='topCard'>
            <div className='topCard1'>
              <Fade left><img className='profilePic' alt='img' src={imagePath}/></Fade>
              <Fade right>
                <div className='profileName' >
                {lastname.toUpperCase()} <br/> {firstname.toUpperCase()}
                </div>
              </Fade>
            </div>
            <Fade bottom><div className='topCard2' dangerouslySetInnerHTML={{ __html: title }} /></Fade>
        </div>
        <div className='middleCard'>
        <Bounce left cascade>
        {address && (
            <div className={isMapFieldClicked ? 'mapFieldClicked' : 'mapField_scit'} onClick={handleMapClick}>
                <div className='mapField1'>
                <LocationOnIcon style={{color:"#c1c1c1"}}/>
                <h4 className='h4_scit'>{address}</h4>
                </div>
                <ChevronRightIcon/>
            </div>)}
            {phoneNumber && (
            <div className={isPhoneCallClicked ? 'mapFieldClicked' : 'mapField_scit'} onClick={handlePhoneCall}>
                <div className='mapField1'>
                <PhoneAndroidIcon style={{color:"#c1c1c1"}}/>
                <h4 className='h4_scit'>{phoneNumber}</h4>
                </div>
                <ChevronRightIcon/>
            </div>)}
            {localPhoneNumber && (
            <div className={isLocalPhoneCallClicked ? 'mapFieldClicked' : 'mapField_scit'} onClick={handleLocalPhoneCall}>
                <div className='mapField1'>
                <LocalPhoneIcon style={{color:"#c1c1c1"}}/>
                <h4 className='h4_scit'>{localPhoneNumber}</h4>
                </div>
                <ChevronRightIcon/>
            </div>)}
            {email && (
            <div className={isEmailClicked ? 'mapFieldClicked' : 'mapField_scit'} onClick={handleEmailClick}>
                <div className='mapField1'>
                <MailOutlineIcon style={{color:"#c1c1c1"}} />
                <h4 className='h4_scit'>{email}</h4>
                </div>
                <ChevronRightIcon/>
            </div>)}
            {website && (
            <div className={isWebsiteClicked ? 'mapFieldFinalClicked' : 'mapFieldFinal_scit'} onClick={handleWebsiteClick}>
                <div className='mapField1'>
                <LanguageIcon style={{color:"#c1c1c1"}}/>
                <h4 className='h4_scit'>{website}</h4>
                </div>
                <ChevronRightIcon/>
            </div>)}
            </Bounce>
        </div>
        <Fade bottom cascade>
        <div className='logo_scit'>
            <img className='logoImg_scit' alt='logo' src={Logo}/>
        </div>
        <div className='bottom_links_scit'>
  { /* Facebook */ }
  {facebookHref && (
    <a href={facebookHref}>
      <FacebookIcon fontSize='large'/>
    </a>
  )}
  { /* Instagram */ }
  {instagramHref && (
    <a href={instagramHref}>
      <InstagramIcon fontSize='large'/>
    </a>
  )}
  { /* YouTube */ }
  {youtubeHref && (
    <a href={youtubeHref}>
      <YouTubeIcon fontSize='large'/>
    </a>
  )}
  { /* Pinterest */ }
  {pinterestHref && (
    <a href={pinterestHref}>
      <PinterestIcon fontSize='large'/>
    </a>
  )}
  { /* LinkedIn */ }
  {linkedinHref && (
    <a href={linkedinHref}>
      <LinkedInIcon fontSize='large'/>
    </a>
  )}
</div>

        <footer className='poweredBy_scit'>
          <div><img alt='powered_by' src={powered_by}/> </div>
            <button onClick={ContactClick}>CREER MA PROPRE CARTE</button>
        </footer>
        </Fade>
    </div>
  )
}

export default BodyCarte