import React from 'react';
import './Web_page2.css';
import webDesignIcon from './web-design.png';
import ecommerceIcon from './ecommerce.png';
import webAppIcon from './web-app.png';
import cmsIcon from './cms.png';
import seoIcon from './seo.png';
import maintenanceIcon from './maintenance.png';
import Slide from 'react-reveal/Slide'; 

const Services = () => {
  return (
    <div className="services-container">
        <Slide bottom>
      <div className="service">
        <img src={webDesignIcon} alt="Conception de Sites Web" className="service-icon" />
        <h2>Conception de Sites Web</h2>
        <p>Création de sites modernes et réactifs, adaptés à votre marque et optimisés pour tous les écrans.</p>
      </div>

      <div className="service">
        <img src={ecommerceIcon} alt="Solutions E-commerce" className="service-icon" />
        <h2>Solutions E-commerce</h2>
        <p>Conception de boutiques en ligne fluides, avec navigation facile et paiements sécurisés.</p>
      </div>

      <div className="service">
        <img src={webAppIcon} alt="Applications Web Personnalisées" className="service-icon" />
        <h2>Applications Web</h2>
        <p>Développement d’applications interactives sur mesure, pour répondre à vos besoins uniques.</p>
      </div>

      <div className="service">
        <img src={cmsIcon} alt="Gestion de Contenu" className="service-icon" />
        <h2>Gestion de Contenu (CMS)</h2>
        <p>Gestion simplifiée de votre contenu grâce à nos solutions CMS, pour un contrôle total de votre site.</p>
      </div>

      <div className="service">
        <img src={seoIcon} alt="Optimisation SEO & Performance" className="service-icon" />
        <h2>Optimisation SEO</h2>
        <p>Boostez votre visibilité avec notre optimisation SEO, pour une meilleure expérience utilisateur.</p>
      </div>

      <div className="service">
        <img src={maintenanceIcon} alt="Maintenance & Support" className="service-icon" />
        <h2>Maintenance & Support</h2>
        <p>Gardez votre site performant et sécurisé avec notre maintenance continue et notre support fiable.</p>
      </div>
      </Slide>
    </div>
  );
};

export default Services;
