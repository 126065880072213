import React, { useEffect } from 'react';
import "./style.css"
import NewNavbar from '../NewNavbar/NewNavbar';
import FooterDesktop from '../Footer/FooterDesktop';

const PolitiqueConfidentialite = () => {
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
            <NewNavbar/>
        <div className="politique-confidentialite">
      <h1>Politique de Confidentialité</h1>
      
      <p>Chez <strong>Techtide Solutions</strong>, la protection de la vie privée et des données personnelles de nos clients est primordiale. Cette Politique de Confidentialité vise à vous informer sur la manière dont nous collectons, utilisons, stockons et protégeons vos informations personnelles conformément aux lois tunisiennes.</p>
      
      <h2>1. Responsable du Traitement des Données</h2>
      <p>
        Techtide Solutions<br />
        Adresse : [02 Ibn Khaldoun C Nasr, 2094, Mnihla]<br />
        Email : [techtide.solutions.tn@gmail.com]<br />
        Téléphone : [+216 51 01 39 89]<br />
      </p>
      <p>
        Le responsable du traitement des données est Techtide Solutions, représentée par Elmay Nizar, qui peut être contacté aux coordonnées ci-dessus.
      </p>

      <h2>2. Données Collectées</h2>
      <p>Dans le cadre de nos activités, nous collectons les informations personnelles suivantes :</p>
      <ul>
        <li><strong>Données d’identification :</strong> nom, prénom, adresse postale, numéro de téléphone, adresse email, etc.</li>
        <li><strong>Informations professionnelles :</strong> nom de l’entreprise, fonction, coordonnées professionnelles.</li>
        <li><strong>Informations de transaction :</strong> historique d’achat, détails de paiement, etc.</li>
        <li><strong>Données de navigation :</strong> adresse IP, type de navigateur, pages visitées sur notre site, etc.</li>
      </ul>
      <p>Ces données sont collectées directement auprès de vous lorsque vous remplissez nos formulaires de contact, passez une commande ou utilisez nos services.</p>

      <h2>3. Utilisation des Données</h2>
      <p>Les données personnelles collectées sont utilisées aux fins suivantes :</p>
      <ul>
        <li><strong>Gestion des commandes et des prestations :</strong> pour traiter vos commandes, gérer la livraison des équipements électroniques et fournir les services informatiques commandés.</li>
        <li><strong>Service client :</strong> pour répondre à vos questions, fournir une assistance et gérer les réclamations.</li>
        <li><strong>Amélioration de nos services :</strong> pour adapter nos offres et proposer des produits et services qui répondent à vos besoins.</li>
        <li><strong>Marketing :</strong> pour vous envoyer des informations sur nos nouveautés, offres spéciales, ou mises à jour de services (sous réserve de votre consentement préalable).</li>
        <li><strong>Respect des obligations légales :</strong> pour nous conformer aux lois et réglementations en vigueur, notamment celles concernant la facturation et la comptabilité.</li>
      </ul>

      <h2>4. Partage des Données</h2>
      <p>
        Techtide Solutions s'engage à ne pas vendre, louer ou échanger vos données personnelles. Cependant, certaines données peuvent être partagées avec :
      </p>
      <ul>
        <li><strong>Nos prestataires de services :</strong> partenaires logistiques, services de paiement, fournisseurs techniques, uniquement dans la mesure où cela est nécessaire pour l’exécution de nos services.</li>
        <li><strong>Les autorités administratives et judiciaires :</strong> lorsque cela est requis par la loi.</li>
      </ul>

      <h2>5. Sécurité des Données</h2>
      <p>
        Nous mettons en œuvre des mesures de sécurité physiques, techniques et organisationnelles pour protéger vos données personnelles contre la perte, le vol, et l'accès non autorisé, notamment par le chiffrement des données sensibles et la restriction des accès.
      </p>

      <h2>6. Conservation des Données</h2>
      <p>Vos données personnelles sont conservées pour une durée limitée, en fonction de la finalité du traitement :</p>
      <ul>
        <li><strong>Données liées aux commandes :</strong> conservées pendant la durée de la relation commerciale, puis archivées pendant la durée nécessaire au respect des obligations légales (5 à 10 ans pour les documents comptables).</li>
        <li><strong>Données de prospection :</strong> conservées pendant 3 ans à compter du dernier contact avec vous.</li>
        <li><strong>Données de navigation :</strong> conservées pendant 13 mois.</li>
      </ul>

      <h2>7. Vos Droits</h2>
      <p>En tant que client de Techtide Solutions, vous disposez des droits suivants sur vos données personnelles :</p>
      <ul>
        <li><strong>Droit d’accès :</strong> vous pouvez obtenir des informations sur les données que nous détenons sur vous.</li>
        <li><strong>Droit de rectification :</strong> vous pouvez demander la correction de vos données si elles sont inexactes ou incomplètes.</li>
        <li><strong>Droit d’opposition :</strong> vous pouvez vous opposer au traitement de vos données pour des motifs légitimes.</li>
        <li><strong>Droit à l’effacement :</strong> vous pouvez demander la suppression de vos données, sous réserve des obligations légales de conservation.</li>
        <li><strong>Droit de portabilité :</strong> vous pouvez recevoir les données que vous nous avez fournies dans un format structuré, couramment utilisé et lisible par machine.</li>
        <li><strong>Droit de retrait du consentement :</strong> vous pouvez retirer votre consentement au traitement de vos données à tout moment.</li>
      </ul>
      <p>Pour exercer vos droits, vous pouvez nous contacter aux coordonnées indiquées dans la section 1.</p>

      <h2>8. Modifications de la Politique de Confidentialité</h2>
      <p>
        Techtide Solutions se réserve le droit de modifier cette Politique de Confidentialité à tout moment. Les mises à jour seront publiées sur notre site internet avec la date de la dernière révision. Nous vous encourageons à consulter régulièrement cette page pour rester informé des modifications éventuelles.
      </p>

      <h2>9. Contact</h2>
      <p>
        Pour toute question concernant notre Politique de Confidentialité ou vos droits relatifs à vos données personnelles, veuillez nous contacter à l’adresse suivante : [techtide.solutions.tn@gmail.com].
      </p>
    </div>
    <FooterDesktop/>
    </div>
  );
};

export default PolitiqueConfidentialite;
