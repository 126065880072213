import React from 'react';
import galata from './galata_tour1.png';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import galata_tour from './galata_tour.jpg';
import Hosni_vcf from './Hosni_vcf.vcf';

const Hosni = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"29989596"}
            email={""}
            vcfFilePath={Hosni_vcf}
          />

          <BodyCarte
            firstname={"Hosni"}
            lastname={"Haswa"}
            title={""}
            address={"Menzah 9"}
            phoneNumber={"29989596"}
            localPhoneNumber={""}
            email={""}
            website={""}
            imagePath={galata_tour}
            Logo={galata}
          />
        </>
    </div>
  );
};

export default Hosni;