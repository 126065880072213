import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from "./BodyCarte";
import "./Carte.css"; 
import ahmed_img from "./ahmed_img.jpeg"
import logo from "./logo.png"
import Mlayah_Ahmed from "./Mlayah_Ahmed.vcf"

const SCIT = () => {
  return (
    <div className='carteStyle_scit'>
        <>
          <NavCarte
            phoneNumber={"+216 20454005"}
            email={"ahmed.mlayah@orange.tn"}
            vcfFilePath={Mlayah_Ahmed}
          />

          <BodyCarte
            firstname={"Ahmed"}
            lastname={"Mlayah"}
            title={"Directeur commercial"}
            address={"15 rue Om Laarayes, Z.I. Saint Gobin, 2014, Megrine"}
            phoneNumber={"+216 20 45 40 05"}
            localPhoneNumber={"+216 71 29 68 88"}
            email={"ahmed.mlayah@orange.tn"}
            website={"https://scit-export.com"}
            imagePath={ahmed_img}
            Logo={logo}
            facebookHref="https://www.facebook.com/scitexport/?locale=fr_FR"
            linkedinHref="https://www.linkedin.com/company/scit-export/?originalSubdomain=tn"
          />
        </>
    </div>
  );
};

export default SCIT;