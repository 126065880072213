import React, { useEffect, useState } from 'react';
import './NewNavbar.css';
import logo from "./logo.png";
import logo23 from "./logo23.png";
import '@fortawesome/fontawesome-free/css/all.min.css';
import panier from "./panier.png";
import user from "./user.png";
import { useNavigate, useLocation } from 'react-router-dom';

const NewNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current path
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1095);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1095);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isActive = (path) => location.pathname === path;

  return (
    <div>
      <div className="wrapper">
        <nav>
          <input type="checkbox" id="show-menu" />
          <label htmlFor="show-menu" className="menu-icon"><i className="fas fa-bars"></i></label>
          <div className="nav-content">
            <div className="Navbarlogo" onClick={() => navigate('/')}><img src={logo23} alt="logo" /></div>
            <ul className="links">
              <li>
                <a 
                  href="#" 
                  className="desktop-link" 
                >
                  Nos Produits <i className="fa-solid fa-angle-down"></i>
                </a>
                <input type="checkbox" id="show-services" />
                <label htmlFor="show-services">Nos Produits <i className="fa-solid fa-angle-down"></i></label>
                <ul>
                  <h1>Personnalisables</h1>
                  <li><a href="#" onClick={() => navigate('/PVC_carte')}>Tech-carte PVC</a></li>
                  <li><a href="#" onClick={() => navigate('/ECO+_carte')}>Tech-carte Eco+</a></li>
                  <li><a href="#" onClick={() => navigate('/Carbone_Card')}>Tech-carte Carbone</a></li>
                  <li><a href="#" onClick={() => navigate('/Metal_Silver')}>Tech-carte Metal Silver</a></li>
                  <li><a href="#" onClick={() => navigate('/Metal_Gold')}>Tech-carte Metal Gold</a></li>
                  <li><a href="#" onClick={() => navigate('/Google_Card')}>Tech-carte Google Review</a></li>
                  <li><a href="#" onClick={() => navigate('/TripAdvisor_card')}>Tech-carte Tripadvisor Review</a></li>
                  <li><a href="#">Tech-carte Wifi</a></li>
                  <h1>Prêt à configurer</h1>
                  <li><a href="#">Tech-carte PVC</a></li>
                  <li><a href="#">Tech-carte Eco+</a></li>
                  <li><a href="#">Tech-carte Carbone</a></li>
                  <li><a href="#">Tech-carte sticker </a></li>
                  <li><a href="#">Tech-carte Metal Silver</a></li>
                  <li><a href="#">Tech-carte Metal Gold</a></li>
                </ul>
              </li>
              <li>
                <a 
                  href="#" 
                  style={isActive('/LaNFC') ? { color: "#489AC3", fontWeight:"600" } : {}} 
                  onClick={() => navigate('/LaNFC')}
                >
                  La NFC
                </a>
              </li>
              <li>
                <a 
                  href="#" 
                  style={isActive('/web') ? { color: "#489AC3", fontWeight:"600" }: {color:"#52C5C8"}} 
                  onClick={() => navigate('/web')}
                >
                  Développement web
                </a>
              </li>
              <li>
                <a 
                  href="#" 
                  style={isActive('/Pro') ? { color: "#489AC3", fontWeight:"600" } : {}} 
                  onClick={() => navigate('/Pro')}
                >
                  Entreprises
                </a>
              </li> 
              <li>
                <a 
                  href="#" 
                  style={isActive('/contact_us') ? { color: "#489AC3", fontWeight:"600" } : {}} 
                  onClick={() => navigate('/contact_us')}
                >
                  Nous contacter
                </a>
              </li>
            </ul>
            <label className="search-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={user} alt='utilisateur' />
              <img src={panier} alt='panier' style={{ marginLeft: "10%" }} />
            </label>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NewNavbar;
