import React, { useState, useRef } from 'react';
import "./ContactForm.css";
import emailjs from '@emailjs/browser';
import { Fade } from 'react-reveal';

const ContactForm = () => {
    const form = useRef();
    const [successMessage, setSuccessMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_x9apz2y', 'template_ulaotus', form.current, 'PerSxUcVtQx41qWk4')
            .then((result) => {
                console.log(result.text);
                form.current.reset(); // This line resets the form
                setSuccessMessage("Email sent successfully");
                setTimeout(() => {
                    setSuccessMessage("");
                }, 3000);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <Fade left>
        <div className='ContactForm'>
            <div className='ContactForm-23'>
                <h3>Nous Contacter</h3>
                <img alt="contact-us" src="https://i.imgur.com/uiK6WJt.png" className='ContactImg'/>
                <h2>Demander Un Devis</h2>
            </div>
            {successMessage && <p style={{ color: '#92A694',backgroundColor:"transparent", borderStyle:"solid",borderColor:"#81C784", borderWidth:"2px",fontSize:"14px",fontWeight:"500",fontFamily:"Segoe UI",margin:"10px 0",padding:"10px 50px",borderRadius:"5px"}}>{successMessage}</p>}
            <form className='ContactForm_1' ref={form} onSubmit={sendEmail}>
                <label className='Label'>Nom et Prénom</label>
                <input className='InputField' type="text" name="user_name" placeholder="Non et Prénom"/>
                <label className='Label'>E-mail</label>
                <input className='InputField' type="email" name="user_email" placeholder="Adress Mail"/>
                <label className='Label'>Numéro de téléphone</label>
                <input className='InputField' type="tel" name="user_phone" placeholder="Numéro de télèphone"/>
                <label className='Label'>Message</label>
                <textarea className='InputField' name="message" placeholder="Ecrivez votre message ici ..."/>
                <input className='FormButton' type="submit" value="Envoyer"/>
            </form>
            <img alt='fleche' src="https://i.imgur.com/o5X8E46.png" className='fleche'/>
        </div>
        </Fade>
    )
}

export default ContactForm;
