import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from '../Carte/BodyCarte';
import "../Carte/Carte.css"; 
import logo_illusion_3D from "./logo_illusion_3D.jpg"
import ranime_img from "./ranime_img.jpeg"
import Ranime_vcf from "./Ranime_vcf.vcf"

const Ranime_Hassine = () => {
  return (
    <div className='carteStyle'>
        <>
          <NavCarte
            phoneNumber={"+21699979658"}
            email={"ranimhassine08@gmail.com"}
            vcfFilePath={Ranime_vcf}
          />

          <BodyCarte
            firstname={"Ranime"}
            lastname={"Hassine"}
            title={"Responsable marketing "}
            address={""}
            phoneNumber={"+21699979658"}
            localPhoneNumber={""}
            email={"ranimhassine08@gmail.com"}
            website={"https://illusion3d.com"}
            imagePath={ranime_img}
            Logo={logo_illusion_3D}
            facebookHref="https://www.facebook.com/ranim.hssn?comment_id=Y29tbWVudDoxNzc0Njk0NjQ5MjI0Njg4XzE3NzQ2OTk2NTU4OTA4NTQ%3D"
            instagramHref="https://www.instagram.com/raniim_hassine/"
            linkedinHref="https://www.linkedin.com/in/ranim-hassine-377b0817a/?originalSubdomain=tn"
          />
        </>
    </div>
  );
};

export default Ranime_Hassine;