import React from 'react';
import './index.css';
import  Facebook  from './facebook.png';
import  instagram  from './instagram.png';
import  whatsapp  from './whatsapp.png';
import  linkedin  from './linkedin.png';
import logo from "./logo.png"
import { useNavigate } from 'react-router-dom';

function FooterDesktop() {
  const navigate = useNavigate();

  return (
    <div className='FooterDesktop'>
    <div className='main-container'>
      <div className='frame'>
        <div className='logo'>
            <img alt="img" src={logo} style={{width:"170px"}}/> 
        </div>
        
      </div>
      <div className='frame-1b'>
        <div className='frame-1c'>
          <span className='informations'>Informations</span>
          <div className='frame-1d'>
            <span className='techtide' onClick={() => navigate('/')}>Techtide</span>
            <span className='devis' onClick={() => navigate('/LaNFC')}>La NFC</span>
            <span className='faq' onClick={() => navigate('/FAQ')}>FAQ</span>
          </div>
        </div>
        <div className='frame-1e'>
          <span className='nos-services'>Nos services</span>
          <div className='frame-1f'>
            <span className='enterprises' onClick={() => navigate('/Pro')}>Entreprises</span>
            <span className='rse-approach' onClick={() => navigate('/RSE')}>Démarche RSE</span>
            <span className='how-it-works' onClick={() => navigate('/web')}>Déveleppement web</span>
          </div>
        </div>
        <div className='frame-20'>
          <span className='useful-links'>Liens utiles</span>
          <div className='frame-21'>
            <span className='support' onClick={() => navigate('/contact_us')}>Nous contacter</span>
            <span className='terms-and-conditions' onClick={() => navigate('/CGV')}>Conditions générales</span>
            <span className='privacy-policy' onClick={() => navigate('/Politique')}>Politique de confidentialité</span>
            <span className='legal-mentions' onClick={() => navigate('/MentionsLegales')}>Mentions légales</span>
          </div>
        </div>
        <div className='frame-22'>
          <span className='contact-us'>Nous contacter</span>
          <div className='frame-23'>
            <div className='frame-24'>
              <span className='address'>Adresse :</span>
              <span className='address-details'>
                02 Ibn Khaldoun C Nasr,
                <br />
                2094, Mnihla
              </span>
            </div>
            <div className='frame-25'>
              <span className='phone-number'>Numéro :</span>
              <span className='phone-number-26'>+216 51 01 39 89</span>
              <span className='phone-number-27'>+216 99 442 060</span>
            </div>
            <div className='frame-28'>
              <span className='email'>E-mail :</span>
              <span className='email-29'>techtide.solutions.tn@gmail.com</span>
            </div>
            <button className='Footerbutton'>
              <span className='contact-form' onClick={() => navigate('/contact_us')}>Formulaire de contact</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className='frame-2a'>
        <a className='empty' href="https://www.instagram.com/techtide_solutions_officiel/" target="_blank" rel="noopener noreferrer"> <img alt="img" src={instagram}/></a>
        <a className='empty' href="https://www.facebook.com/profile.php?id=61567054921006" target="_blank" rel="noopener noreferrer"> <img alt="img" src={Facebook}/></a>
        <a className='empty' href="https://wa.me/21651013989" target="_blank" rel="noopener noreferrer"><img alt="img" src={whatsapp}/></a>
      </div>
      <div className='all_rights'>
      © 2025 techtide_solutions_officiel. All Rights Reserved.
      </div>
    </div>
  );
}

export default FooterDesktop;
