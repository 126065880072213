import React, { useEffect } from 'react'
import "./Produits.css"
import NewNavbar from '../NewNavbar/NewNavbar';
import tunisie from "./tunisie.png"
import table from "./table.png"
import invitation from "./invitation.png"
import augmentation from "./augmentation.png"
import FooterDesktop from '../Footer/FooterDesktop';
import { useNavigate } from 'react-router-dom';

const Card_Google = () => {

  const navigate = useNavigate();

    const handleRedirect = () => {
        // Redirect to Google
        window.open("https://wa.me/21651013989?text=Carte de visite NFC - Avis Google", "_blank"); 
      };

      useEffect(() => {
        // Scroll to top with smooth behavior when the component mounts
        window.scrollTo({ top: 0 });
      }, []);

    return (
        <div className="produit">
            <NewNavbar/>
        <div className="produit-container">
        <div className="produit-image">
        <img 
            src="https://i.imgur.com/TD7ZQV2.png" 
            alt="produit" 
          />
             <img 
            src="https://i.imgur.com/QTCEkcl.jpeg" 
            alt="produit" 
          />
        </div>
          <div className="produit-content">
            <h1>Carte NFC Avis Google</h1>
            <h2>27.700 DT Hors Taxe <img alt='tn' src={tunisie} /></h2>
            <button onClick={handleRedirect}> 🛒  Commander</button>
            <p>Élevez votre réputation en ligne avec notre Carte NFC Avis Google, 
                un outil de taille aussi transportable qu’une carte bancaire qui permet de recueillir facilement les avis de vos clients. 
                Grâce à la technologie NFC, vos clients peuvent laisser des avis en un seul geste, 
                simplifiant ainsi le processus et garantissant une interaction fluide.</p>
                <div>
                    <h3>Pourquoi choisir la carte NFC avis google pour votre entreprise ?</h3>
                    <p>🚀 <strong>Connectivité Instantanée :</strong> Obtenez des avis en quelques secondes, offrant une expérience utilisateur sans effort.</p>
                    <p>👜 <strong>Transportable :</strong> Son format réduit et facilement transportable séduit afin de le déposer directement là où se trouve votre clients.</p>
                    <p>✔️ <strong>Compatibilité Universelle :</strong> Fonctionne avec tous les smartphones NFC, garantissant une accessibilité maximale pour vos clients avec le QR Code aussi présent.</p>
                    <p>💡 <strong>Facilité d’Utilisation :</strong> Installez la carte en quelques minutes et commencez à recevoir des avis positifs immédiatement.</p>
                    <h3>Avantages incontestables :</h3>
                    <p>🌟 <strong>Réputation Instantanée :</strong> Transformez chaque interaction client en une opportunité de renforcer votre réputation en ligne avec des avis authentiques.</p>
                    <p>🛡️ <strong>Renforcement de la Confiance :</strong> Démontrez votre engagement envers la satisfaction client, renforçant ainsi la confiance de vos futurs clients.</p>
                    <p>📈 <strong>Croissance de l’Activité : </strong> Les avis positifs peuvent se traduire par une augmentation de la fréquentation et des opportunités d’affaires.</p>
                </div>
          </div>
        </div>
        <div className="order">
        <h1>Comment se déroule la commande ?</h1>
        <h3>Du choix de votre Tech-carte à sa livraison</h3>
        <div className="order-process">
      <div className="process-step">
        <div className="step-number">1</div>
        <div className="step-title">Choix du matériaux</div>
        <div className="step-description">
          Choisissez votre Tech-carte et la quantité en fonction du nombre de collaborateurs à équiper.
          <br />
          Il existe la PVC-classique, la eco+ , la carbone, la Metal Gold et la Metal Silver.
        </div>
      </div>

      <div className="process-step">
        <div className="step-number">2</div>
        <div className="step-title">Personnalisation</div>
        <div className="step-description">
          Vous recevez un lien pour envoyer vos informations (Nom, Prénom, logo, image, etc...). C'est notre équipe design qui s'occupera avec vous de la personnalisation.
        </div>
      </div>

      <div className="process-step">
        <div className="step-number">3</div>
        <div className="step-title">Livraison</div>
        <div className="step-description">
        Votre commande sera préparée avec soin et expédiée dans un délai de 4 à 5 jours ouvrables à compter de la date de passation. Nous mettons tout en œuvre pour vous garantir une livraison rapide et efficace.
        </div>
      </div>
    </div>
    </div>
    <div className="benefits-section">
  <h2>Comment ça marche ?</h2>
  <p className="subtitle">
  Transformez chaque interaction en une opportunité de croissance avec la carte NFC avis Google.
  </p>
  <div className="benefits-container">
    <div className="benefit">
      <img src={table} alt="Économies" />
      <h3>1 - Déposez à la table de votre client</h3>
      <p>
      Emmenez la carte directement au client pour maximiser les interactions.
      </p>
    </div>
    <div className="benefit">
      <img src={invitation} alt="Développez votre réseau" />
      <h3>2 - Invite à l’Avis</h3>
       <p>
       Encouragez vos clients à laisser des avis en un simple toucher, cela leur prendra uniquement 15 secondes.
       </p>
    </div>
    <div className="benefit">
      <img src={augmentation} alt="Partagez vos infos" />
      <h3>3 - Regardez Votre Réputation S’épanouir</h3>
      <p>
      Chaque avis compte et contribue à la construction d’une réputation positive.
      </p>
    </div>
  </div>
  <img className='avis_google' alt='google' src='https://i.imgur.com/qi65g5X.png'/>
      <div className='under_video'>
      <h3>Idéale pour les restaurants, les commerces locaux, 
        les hôtels et toute entreprise axée sur l’expérience client sans qu’ils aient besoin de se déplacer. 
        Accompagné par exemple l’addition avec la carte d’avis Google NFC</h3>
      <button onClick={handleRedirect}> 🛒  Commander</button>
      </div>
</div>
<div className='produits_similaires'>
        <h1>Produits similaires</h1>
        <div className='produits_similaires_container'>
        <div className='produits_similaires_part' onClick={() => navigate('/TripAdvisor_card')}>
          <img alt='produit_img' src='https://i.imgur.com/q9oKCw1.jpeg'/>
          <h3>Carte NFC Avis Tripadvisor</h3>
        </div>
        <div className='produits_similaires_part' onClick={() => navigate('/Metal_Gold')}>
          <img alt='produit_img' src='https://i.imgur.com/p2mR2xe.jpeg'/>
          <h3>Tech-carte metal Gold personnalisée</h3>
        </div>
        <div className='produits_similaires_part' onClick={() => navigate('/PVC_carte')}>
          <img alt='produit_img' src='https://i.imgur.com/O974Z7L.jpeg'/>
          <h3>Tech-carte PVC personnalisée</h3>
        </div>
        </div>
      </div>
      <FooterDesktop/>
        </div>
      );
    };

export default Card_Google