import React, { useEffect } from 'react';
import './DemarcheRSE.css'; 
import NewNavbar from '../NewNavbar/NewNavbar';
import FooterDesktop from '../Footer/FooterDesktop';

const DemarcheRSE = () => {
    useEffect(() => {
        // Scroll to top with smooth behavior when the component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

  return (
    <div className="demarche-rse">
        <NewNavbar/>
      <h1 className="demarche-rse-h1">Notre Démarche RSE</h1>
      <div className="section">
        <h2 className="demarche-rse-h2">Engagement pour l’Environnement</h2>
        <div className="content">
          <div className="point bg1">
            <p><strong>Utilisation Raisonnée des Ressources </strong><br/><br/> Nous nous engageons à minimiser notre impact environnemental en utilisant les ressources naturelles de manière responsable.</p>
          </div>
          <div className="point bg2">
            <p><strong>Réduction des Émissions </strong><br/><br/> Nous travaillons à limiter notre empreinte carbone en choisissant des partenaires partageant nos valeurs écologiques.</p>
          </div>
          <div className="point bg3">
            <p><strong>Recyclage et Gestion des Déchets Électroniques </strong><br/><br/> Nous encourageons le recyclage des équipements électroniques obsolètes et la réparation pour prolonger leur cycle de vie.</p>
          </div>
        </div>
      </div>

      <div className="section">
        <h2>Responsabilité Sociale et Communautaire</h2>
        <div className="content">
          <div className="point bg4">
            <p><strong>Formation et Éducation Technologique </strong><br/><br/> Techtide Solutions contribue au développement de la communauté par des formations gratuites et des webinaires.</p>
          </div>
          <div className="point bg5">
            <p><strong>Support aux Initiatives Locales </strong><br/><br/> Nous collaborons avec des associations pour aider les organisations à but non lucratif en offrant des solutions numériques.</p>
          </div>
        </div>
      </div>

      <div className="section">
        <h2>Bien-être et Développement des Employés</h2>
        <div className="content">
          <div className="point bg6">
            <p><strong>Égalité et Inclusion </strong><br/><br/> Nous favorisons la diversité, l’égalité, et l’inclusion au sein de notre équipe avec des conditions de travail respectueuses.</p>
          </div>
          <div className="point bg7">
            <p><strong>Formation Continue </strong><br/><br/> Nos collaborateurs bénéficient de programmes de formation continue pour rester à jour sur les évolutions technologiques.</p>
          </div>
          <div className="point bg8">
            <p><strong>Équilibre Vie Professionnelle - Vie Personnelle </strong><br/><br/> Nous encourageons un équilibre sain en offrant des options de travail flexible.</p>
          </div>
        </div>
      </div>

      <div className="section">
        <h2>Développement Durable à Travers les Technologies</h2>
        <div className="content">
          <div className="point bg9">
            <p><strong>Solutions Technologiques Durables </strong><br/><br/> Nous développons des solutions numériques pour promouvoir un avenir durable et inclusif.</p>
          </div>
          <div className="point bg10">
            <p><strong>Accessibilité des Services </strong><br/><br/> Nous simplifions l’accès aux nouvelles technologies pour favoriser l'inclusion numérique en Tunisie.</p>
          </div>
        </div>
      </div>

      <div className="section_last">
        <h2>Conformité et Transparence</h2>
        <div className="content">
          <div className="point bg11">
            <p><strong>Conformité aux Réglementations </strong><br/><br/> Nous respectons toutes les lois et réglementations, notamment en matière de protection des données et de cybersécurité.</p>
          </div>
          <div className="point bg12">
            <p><strong>Transparence des Pratiques </strong><br/><br/> Nous adoptons une éthique de travail basée sur la confiance, la transparence, et le respect mutuel.</p>
          </div>
        </div>
      </div>
      <FooterDesktop/>
    </div>
  );
};

export default DemarcheRSE;
