import React from 'react';
import './Web_page3.css';
import centeredImage from './centeredImage.jpg';
import experienceIcon from './experienceIcon.png';
import productsIcon from './productsIcon.png';
import approachIcon from './approachIcon.png';
import pricingIcon from './pricingIcon.png';
import deliveryIcon from './deliveryIcon.png';
import supportIcon from './supportIcon.png';
import { Fade } from 'react-awesome-reveal';

const Web_page3 = () => {
  return (
    <section className="why-choose-us">
      <h2>Pourquoi Nous Choisir</h2>
      <p>Découvrez pourquoi nous sommes le choix idéal pour vous accompagner dans votre projet.</p>

      <div className="why-choose-us-container">
        <div className="why-choose-items">
        <Fade cascade damping={0.5}>
          <div className="why-choose-item">
            <div>
              <h3>Expérience</h3>
              <p>"Fort de notre expérience, nous proposons des solutions adaptées aux besoins de chaque client."</p>
            </div>
            <img src={experienceIcon} alt="Expérience Icon" className="choose_us_icon" />
          </div>

          <div className="why-choose-item">
            <div>
              <h3>Produits</h3>
              <p>Nous offrons une gamme variée de produits innovants, répondant aux besoins actuels et futurs de nos clients.</p>
            </div>
            <img src={productsIcon} alt="Produits Icon" className="choose_us_icon" />
          </div>

          <div className="why-choose-item">
            <div>
              <h3>Approche</h3>
              <p>Notre approche est orientée client, garantissant une satisfaction à chaque étape du processus.</p>
            </div>
            <img src={approachIcon} alt="Approche Icon" className="choose_us_icon" />
          </div>
          </Fade>
        </div>

        <div className="center-image">
          <img src={centeredImage} alt="Pourquoi nous choisir" />
        </div>

        <div className="why-choose-items">
        <Fade cascade damping={0.5}>
          <div className="why-choose-item2">
          <img src={pricingIcon} alt="Tarification Icon" className="choose_us_icon2" />
            <div>
              <h3>Tarification</h3>
              <p>Nous offrons des services de qualité à des prix compétitifs, avec des options flexibles adaptées à tous les budgets.</p>
            </div>
          </div>

          <div className="why-choose-item2">
          <img src={deliveryIcon} alt="Livraison Icon" className="choose_us_icon2" />
            <div>
              <h3>Livraison</h3>
              <p>Une livraison rapide et fiable qui respecte les délais pour vous assurer la meilleure expérience possible.</p>
            </div>
          </div>

          <div className="why-choose-item2">
          <img src={supportIcon} alt="Support Icon" className="choose_us_icon2" />
            <div>
              <h3>Support</h3>
              <p>Notre équipe est à votre disposition pour répondre à toutes vos questions et vous fournir un soutien continu.</p>
            </div>
          </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Web_page3;
