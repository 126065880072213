import React from 'react'
import "./Swiper.css"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'; 

import 'swiper/swiper-bundle.css';

const SwiperTechTide = () => {

    const swiperStyle = {
        backgroundColor: '#f3f3f3',
        paddingBottom:"5%",
        paddingTop:"5%",
        paddingRight:"2%",
        paddingLeft:"2%",
        width:"90%"
      };
    
      return (
       <Swiper
      style={swiperStyle}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      navigation
      slidesPerView={1}
      breakpoints={{
        768: {
          slidesPerView: 2,
        },
      }}
    >
          <SwiperSlide>
          <figure className="fir-image-figure">
        <div
            className="fir-imageover"
            rel="noopener"
            target="_blank"
            
        >
            <img
            className="fir-author-image fir-clickcircle"
            src="https://fir-rollup.firebaseapp.com/de-sm.jpg"
            alt="David East - Author"
            />
            <div className="fir-imageover-color"></div>
            <img
            className="fir-imageover-image fir-clickcircle"
            src="https://i.imgur.com/iXcRlaR.png"
            alt="TECHTIDE Logo"
            />
        </div>

        <figcaption>
            <div className="fig-author-figure-title">David East</div>
            <div className="fig-author-figure-title">
            Engineer at Google on Firebase.
            </div>
            <div className="fig-author-figure-title">
            Jan. 16th, 2023 
            </div>
        </figcaption>
        </figure>
          </SwiperSlide>
          <SwiperSlide>
          <figure className="fir-image-figure">
        <div
            className="fir-imageover"
            rel="noopener"
            target="_blank"
            
        >
            <img
            className="fir-author-image fir-clickcircle"
            src="https://fir-rollup.firebaseapp.com/de-sm.jpg"
            alt="David East - Author"
            />
            <div className="fir-imageover-color"></div>
            <img
            className="fir-imageover-image fir-clickcircle"
            src="https://i.imgur.com/iXcRlaR.png"
            alt="TECHTIDE Logo"
            />
        </div>

        <figcaption>
            <div className="fig-author-figure-title">David East</div>
            <div className="fig-author-figure-title">
            Engineer at Google on Firebase.
            </div>
            <div className="fig-author-figure-title">
            Jan. 16th, 2023 
            </div>
        </figcaption>
        </figure>
          </SwiperSlide>
          <SwiperSlide>
          <figure className="fir-image-figure">
        <div
            className="fir-imageover"
            rel="noopener"
            target="_blank"
            
        >
            <img
            className="fir-author-image fir-clickcircle"
            src="https://fir-rollup.firebaseapp.com/de-sm.jpg"
            alt="David East - Author"
            />
            <div className="fir-imageover-color"></div>
            <img
            className="fir-imageover-image fir-clickcircle"
            src="https://i.imgur.com/iXcRlaR.png"
            alt="TECHTIDE Logo"
            />
        </div>

        <figcaption>
            <div className="fig-author-figure-title">David East</div>
            <div className="fig-author-figure-title">
            Engineer at Google on Firebase.
            </div>
            <div className="fig-author-figure-title">
            Jan. 16th, 2023 
            </div>
        </figcaption>
        </figure>
          </SwiperSlide>
          <SwiperSlide>
          <figure className="fir-image-figure">
        <div
            className="fir-imageover"
            rel="noopener"
            target="_blank"
            
        >
            <img
            className="fir-author-image fir-clickcircle"
            src="https://fir-rollup.firebaseapp.com/de-sm.jpg"
            alt="David East - Author"
            />
            <div className="fir-imageover-color"></div>
            <img
            className="fir-imageover-image fir-clickcircle"
            src="https://i.imgur.com/iXcRlaR.png"
            alt="TECHTIDE Logo"
            />
        </div>

        <figcaption>
            <div className="fig-author-figure-title">David East</div>
            <div className="fig-author-figure-title">
            Engineer at Google on Firebase.
            </div>
            <div className="fig-author-figure-title">
            Jan. 16th, 2023 
            </div>
        </figcaption>
        </figure>
          </SwiperSlide>
          <SwiperSlide>
          <figure className="fir-image-figure">
        <div
            className="fir-imageover"
            rel="noopener"
            target="_blank"
            
        >
            <img
            className="fir-author-image fir-clickcircle"
            src="https://fir-rollup.firebaseapp.com/de-sm.jpg"
            alt="David East - Author"
            />
            <div className="fir-imageover-color"></div>
            <img
            className="fir-imageover-image fir-clickcircle"
            src="https://i.imgur.com/iXcRlaR.png"
            alt="TECHTIDE Logo"
            />
        </div>

        <figcaption>
            <div className="fig-author-figure-title">David East</div>
            <div className="fig-author-figure-title">
            Engineer at Google on Firebase.
            </div>
            <div className="fig-author-figure-title">
            Jan. 16th, 2023 
            </div>
        </figcaption>
        </figure>
          </SwiperSlide>
          <SwiperSlide>
          <figure className="fir-image-figure">
        <div
            className="fir-imageover"
            rel="noopener"
            target="_blank"
            
        >
            <img
            className="fir-author-image fir-clickcircle"
            src="https://fir-rollup.firebaseapp.com/de-sm.jpg"
            alt="David East - Author"
            />
            <div className="fir-imageover-color"></div>
            <img
            className="fir-imageover-image fir-clickcircle"
            src="https://i.imgur.com/iXcRlaR.png"
            alt="TECHTIDE Logo"
            />
        </div>

        <figcaption>
            <div className="fig-author-figure-title">David East</div>
            <div className="fig-author-figure-title">
            Engineer at Google on Firebase.
            </div>
            <div className="fig-author-figure-title">
            Jan. 16th, 2023 
            </div>
        </figcaption>
        </figure>
          </SwiperSlide>
        </Swiper>
      );
    };

export default SwiperTechTide