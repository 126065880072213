import React from 'react';
import NavCarte from '../Carte/NavCarte';
import BodyCarte from "./BodyCarte";
import "./Carte.css"; 
import mab from "./mab.jpeg"
import logo_olissey from "./logo_olissey.png"
import Mohamed_Ali_Boubaker from "./Mohamed_Ali_Boubaker.vcf"

const MAB = () => {
  return (
    <div className='carteStyle_olissey'>
        <>
          <NavCarte
            phoneNumber={"+21655085000"}
            email={"dali@olissey.com"}
            vcfFilePath={Mohamed_Ali_Boubaker}
          />

          <BodyCarte
            firstname={"Mohamed Ali"}
            lastname={"Boubaker"}
            title={"Co founder"}
            address={"9 Rue Jerusalem Tunis 1002"}
            phoneNumber={"+21655085000"}
            localPhoneNumber={""}
            email={"dali@olissey.com"}
            website={"https://olissey.com"}
            imagePath={mab}
            Logo={logo_olissey}
            facebookHref="https://www.facebook.com/Olisseyoliveoil/"
            instagramHref="https://www.instagram.com/olissey/"
            pinterestHref="https://www.pinterest.fr/MyOlissey/"
            linkedinHref="https://www.linkedin.com/in/mohamed-ali-boubaker/"
          />
        </>
    </div>
  );
};

export default MAB;