import React, { useEffect } from 'react'
import "./style.css"
import NewNavbar from '../NewNavbar/NewNavbar'
import NfcLogo from './NfcLogo.png'
import lanfc_main1 from "./lanfc_main1.png"
import lanfc_main2 from "./lanfc_main2.png"
import lanfcWave from "./lanfcWave.png"
import lanfcWave_reverse from "./lanfcWave_reverse.png"
import payGIF from "./payGIF.gif"
import FooterDesktop from '../Footer/FooterDesktop'
import temps_rapide from "./temps_rapide.png"
import digital_transformation from "./digital_transformation.png"
import smart_devices from "./smart_devices.png"

const LaNFC = () => {
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className='la_nfc'>
      <NewNavbar/>
      <div className='lanfc_top'>
      <div className='lanfc_top1'>
        <h1>NFC</h1>
        <h2>Qu'est ce que la NFC</h2>
        <p>la NFC ou encore Near Field Communication est comme son nom l'indique une technologie qui permet 
          de communiquer entre 2 appareils compatibles situées à une courte distance l'un de l'autre (environ 2cm)</p>
      </div>
      <div className='lanfc_top2'>
        <img alt='nfc' src={NfcLogo}/>
      </div>
      </div>
      <div className='lanfc_mid'>
      <div className='lanfc_mid_img'>
        <img alt='nfc' src={lanfc_main1}/>
      </div>
      <div className='lanfc_mid_img'>
        <img alt='nfc' src={lanfc_main2}/>
      </div>
      </div>
      <div className='lanfc_wave'>
        <img alt='nfc' src={lanfcWave}/>
      </div>
      <div className='lanfc_mid2'>
        <div className='lanfc_mid2_text'>
          <h1>Une techologie que vous utilisez déjà</h1>
          <p>le nom NFC peux vous paraitre inconnu il y a de grandes chances que vous ayez déjà ulisisé cette technologie
            lors d'un de vos paiement sans contact
          </p>
        </div>
        <div className='lanfc_mid2_img'>
          <img alt='gif' src={payGIF}/>
        </div>
      </div>
      <div className='lanfc_wave'>
        <img alt='nfc' src={lanfcWave_reverse}/>
      </div>
      <div className='lanfc_bottom'>
        <div className='lanfc_bottom1'>
          <h1>Mais pas seulement</h1>
          <p>Cette technologie ne permet pas uniquement le paiement sans contact, cette innovation peut être déclinée
            dans plusieurs autres application et surtout dans le domaine du marketing
          </p>
        </div>
        <div className='lanfc_bottom2'>
          <div className='lanfc_bottom1_carte'>
          <img alt='nfc' src={temps_rapide}/>
          <h1>RAPIDITÉ</h1>
            <p>Partagez vos informations de contact instantanément en un simple frôlement</p>
          </div>
          <div className='lanfc_bottom1_carte'>
          <img alt='nfc' src={digital_transformation}/>
          <h1>DIGITAL</h1>
            <p>Plus besoin d'acheter des tas de cartes visites avec cette carte modifiable à souhait</p>
          </div>
          <div className='lanfc_bottom1_carte'>
          <img alt='nfc' src={smart_devices}/>
          <h1>COMPATIBILE</h1>
            <p>Fonctionne avec tout les appareils IOS et Android compatibles NFC</p>
          </div>
        </div>
      </div>
      <FooterDesktop/>
    </div>
  )
}

export default LaNFC