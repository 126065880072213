import React, { useEffect } from 'react'
import "./style.css"
import NewNavbar from '../NewNavbar/NewNavbar'
import FooterDesktop from '../Footer/FooterDesktop'
import stat from "./stat.png"
import acces from "./acces.png"
import suivi from "./suivi.png"
import imgPRO from './imgPRO.png'
import { useNavigate } from 'react-router-dom'
import part5_bg from "./part5_bg.png"

const ProSolutions = () => {

  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  return (
    <div>
      <NewNavbar/>
      <div className='ProSolutions1'>
        <h1>NOS SOLUTIONS PROS</h1>
        <img alt='img' src={part5_bg}/>
      </div>
      <div className='ProSolutions2'>
        <h1 className='ProSolutions2_h1'>COMMENT FONCTIONNE LA NFC</h1>
        <h2 className='ProSolutions2_h2'>LA PARTIE UN PEU GEEK DE LA PRÉSENTATION</h2>
        <div className='ProSolutions2_2'>
          <div>
            <h1>LA NFC UNE COUSINE DE LA RFID</h1>
            <p>si vous êtes familiers avec la RFID et bien la NFC fonctionnes sur le même principe</p>
          </div>
          <div>
            <h1>MODE DE
            COMMUNICATION</h1>
            <p>la NFC communique de manière sans fil sur des ondes radios HF (13.5Mhz) cette fréquence est également utilisée par certaines cartes RFID</p>
          </div>
          <div>
            <h1>SECURITÉ</h1>
            <p>la communications via cette technologie se fait de manière sécurisée en utilisant des protocoles de sécurité tels que ISO/IEC 18092 et ISO/IEC 14443 </p>
          </div>
          <div>
            <h1>FONCTIONNEMENT</h1>
            <p>les puces NFC fonctionnent de manière autonomes et n'ont pas besoin d'êtres alimentées et il suffit d'approcher son smartphone pour qu'elles soient lues</p>
          </div>
        </div>
      </div>
      <div class="nfc-uses">
  <h2>À quoi me servirait la NFC</h2>
  <div class="nfc-uses-container">
    <div class="nfc-item">
      <div class="nfc-icon">🌐</div>
      <div class="nfc-text">
        <h3>LANDING PAGE</h3>
        <p>à rediriger vers vos campagnes de landing page qui font déjà leur preuves.</p>
      </div>
    </div>
    <div class="nfc-item">
      <div class="nfc-icon">ℹ️</div>
      <div class="nfc-text">
        <h3>INFORMATIONS INSTANTANÉES</h3>
        <p>partagez l'information avec vos clients demanière instantanée.</p>
      </div>
    </div>
    <div class="nfc-item">
      <div class="nfc-icon">👥</div>
      <div class="nfc-text">
        <h3>EXPÉRIENCE INTERACTIVE</h3>
        <p>proposez a vos clients une expérience interactive et plus engageante.</p>
      </div>
    </div>
    <div class="nfc-item">
      <div class="nfc-icon">📞</div>
      <div class="nfc-text">
        <h3>CALL TO ACTION</h3>
        <p>selon vos besoins un tag NFC peut constituer un call to action physique pour laisser par exemple un avis sur internet.</p>
      </div>
    </div>
  </div>
</div>
<div class="nfc-advantages-container">
  <div class="nfc-advantages-header">
  <div class="nfc-advantages-header_content">
    <h1>LES AVANTAGES DE LA NFC</h1>
    <p>par rapport aux methodes classiques</p>
    </div>
  </div>
  <div class="nfc-advantages-cards">
    <div class="advantage-card">
      <img src={stat} alt="Icon 1" />
      <p>un meilleur taux de conversion et un engagement amélioré</p>
    </div>
    <div class="advantage-card">
      <img src={acces} alt="Icon 2" />
      <p>un accès a l'information quasi instantané et sans efforts</p>
    </div>
    <div class="advantage-card">
      <img src={suivi} alt="Icon 3" />
      <p>un suivi des données individualisé pour une compréhension des habitues des consommateurs</p>
    </div>
  </div>
</div>
<div className='pro_bottom'>
  <button onClick={() => navigate('/contact_us')}>CONTACT</button>
  <div><img alt='img' src={imgPRO}/></div>
</div>
      <FooterDesktop/>
    </div>
  )
}

export default ProSolutions