import React from 'react'
import './ContactTop.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Fade } from 'react-reveal';
const ContactTop = () => {
  return (
    <Fade right>
    <div className='ContactTop'>
    <img alt='service_client' src="https://i.imgur.com/jHZKSYT.png" className='service_client'/>
      <div className='ContactTop_2'>
        <div className='ContactTop_2-2'>
          <img alt='img' src="https://i.imgur.com/iVetmjq.png"/>
          <p>+216 51 01 39 89</p>
        </div>
        <div className='ContactTop_2-3'>
        <img alt='img' src="https://i.imgur.com/bfnLp6T.png"/>
          <p>techtide.solutions.tn@gmail.com</p>
        </div>
        <div className='ContactTop_2-4'>
        <img alt='img' src="https://i.imgur.com/YAuMt0d.png"/>
          <p>02 Ibn Khaldoun C Nasr,
          2094, Mnihla</p>
        </div>
        <div className='ContactTop_2-5'>
        <a href="https://www.facebook.com/profile.php?id=61567054921006" target="_blank" rel="noopener noreferrer">
  <FacebookIcon style={{ color: "#F8F8F8" }} fontSize="large" />
</a>
<a href="https://www.instagram.com/techtide_solutions_officiel/" target="_blank" rel="noopener noreferrer">
  <InstagramIcon style={{ color: "#F8F8F8" }} fontSize="large" />
</a>
<a href="https://wa.me/21651013989" target="_blank" rel="noopener noreferrer">
  <WhatsAppIcon style={{ color: "#F8F8F8" }} fontSize="large" />
</a>

        </div>
      </div>
    </div>
    </Fade>
  )
}

export default ContactTop